import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Tab } from '@mui/material'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Helmet } from 'react-helmet-async'
import { socketEventEmitter } from '../socket'
import { useProject } from '../context/ProjectContext'
import PageService from '../services/PageService'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import DetailsTab from '../components/testCase/tabs/details/DetailsTab'
import RunsTab from '../components/testCase/tabs/runs/RunsTab'

const TestCase = () => {
  const { id } = useParams()
  const { selectedProject } = useProject()
  const [activeTabValue, setActiveTabValue] = useState('1')
  const [testCase, setTestCase] = useState(null)
  const [pageToggler, setPageToggler] = useState(false)

  useEffect(() => {
    const getTestCase = async () => {
      try {
        const testCase = await PageService.getTestCaseById(id)
        // console.log(testCase)
        setTestCase(testCase)
      } catch (error) {
        console.error('Error fetching test case:', error)
      }
    }

    getTestCase()

    const handleTaskCompleted = async (data) => {
      if (data.testCaseId === id && data.type === 'Test Case') {
        setPageToggler(!pageToggler)
      }
    }

    const handleTaskFailed = async (data) => {
      if (data.testCaseId === id && data.type === 'Test Case') {
        setPageToggler(!pageToggler)
      }
    }

    socketEventEmitter.on('taskCompleted', handleTaskCompleted)
    socketEventEmitter.on('taskFailed', handleTaskFailed)

    return () => {
      socketEventEmitter.off('taskCompleted', handleTaskCompleted)
      socketEventEmitter.off('taskFailed', handleTaskFailed)
    }
    // eslint-disable-next-line
  }, [id, selectedProject?.id, pageToggler])

  const handleChangeActiveTab = (event, newValue) => {
    setActiveTabValue(newValue)
  }

  return (
    <Box className="testCase">
      <Helmet>
        <title>{`Treegress - ${selectedProject.name} ${testCase?.pageLabel} ${testCase?.testCaseName}`}</title>
      </Helmet>
      <Header title={testCase?.testCaseName}/>
      <Box sx={{ display: 'flex', pt: '56px' }}>
        <Sidebar />
        <Box className="testCaseContentContainer">
          <TabContext value={activeTabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'rgba(45,67,86,0.5)' }}>
              <TabList onChange={handleChangeActiveTab}>
                <Tab label="Test Case Details" value="1" sx={{ fontSize: '1.25rem', textTransform: 'capitalize' }} />
                <Tab label="Test Runs" value="2" sx={{ fontSize: '1.25rem', textTransform: 'capitalize' }} />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ p: "16px 0" }}>
              <DetailsTab testCase={testCase} setActiveTabValue={setActiveTabValue}/>
            </TabPanel>
            <TabPanel value="2" sx={{ p: "16px 0" }}>
              <RunsTab testCase={testCase}/>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Box>
  )
}

export default TestCase