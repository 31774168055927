import { useState, useRef } from 'react'
import { Box, Typography, IconButton, Tooltip, LinearProgress, Alert } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PageService from '../../../../services/PageService'
import TestCaseActions from './TestCaseActions'
import Steps from './Steps'
import VideoPlayer from '../../../VideoPlayer'
import DeleteTestCasesConfirmationModal from '../../../modals/DeleteTestCaseConfirmationModal'

const DetailsTab = ({ testCase, setActiveTabValue }) => {
  const videoRef = useRef(null)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const handleRunTestCase = async () => {
    try {
      await PageService.runTestCase(testCase?.id)
      setActiveTabValue('2')
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenLink = () => {
    window.open(testCase.pageUrl, '_blank', 'noopener,noreferrer')
  }

  const handleSeek = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time
      // videoRef.current.play()
    }
  }

  const handleOpenDeleteDialog = () => {
    setDeleteOpen(true)
  }

  return (
    <Box className="testCaseDetailsTab">
      <Box className="testCaseDetailsTabTitle">
        <Typography variant="h6" color="primary">
          {testCase?.testCaseName}
        </Typography>
        <TestCaseActions
          testCase={testCase}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
          handleRunTestCase={handleRunTestCase}
        />
      </Box>
      <Box className="testCaseDetailsTabPageUrl">
        <Typography variant="body1" color="primary" className="testCaseDetailsTabLabel">
          Url:&nbsp;
        </Typography>
        <Typography variant="body1" color="primary">
          {testCase?.pageUrl}
        </Typography>
        <Tooltip
          title="Open in a new tab"
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                backgroundColor: "primary.main",
              },
            },
          }}
        >
          <IconButton sx={{ ml: 0.5 }} onClick={handleOpenLink}>
            <OpenInNewIcon color="primary" />
          </IconButton>
        </Tooltip>
      </Box>
      <Box className="testCaseDetailsTabPageDescription">
        <Typography variant="body1" color="primary" className="testCaseDetailsTabLabel">
          Description:&nbsp;
        </Typography>
        <Typography variant="body1" color="primary">
          {testCase?.description}
        </Typography>
      </Box>
      <Box className="testCaseDetailsTabContent">
        {testCase && (testCase.status === "COMPLETED" || testCase.status === "FAILED") ? (
          <>
            <Box className="testCaseDetailsTabContentSteps">
              <Typography variant="body1" color="primary" className="testCaseDetailsTabLabel">
                Steps:
              </Typography>
              { testCase?.steps ? (
                <Steps
                  steps={testCase.steps}
                  handleSeek={handleSeek}
                />
              ) : null }
              { testCase.status === "COMPLETED" || testCase.status === "FAILED" ? (
                <Box
                  className="testCaseDetailsTabContentAssertion"
                  sx={{
                    backgroundColor: testCase.common_result === "SUCCESS" ? "rgba(25, 135, 84, 0.1)" : "rgba(211, 47, 47, 0.1)",
                  }}
                >
                  { testCase.is_positive ?
                    <strong style={{ display: 'block' }}>{testCase?.steps ? testCase?.steps.length + 1 : 1}. Assertion: </strong> :
                    testCase.common_result === "SUCCESS" ?
                      <strong style={{ display: 'block' }}>{testCase?.steps ? testCase?.steps.length + 1 : 1}. Assert the error message: </strong> :
                      <strong style={{ display: 'block' }}>{testCase?.steps ? testCase?.steps.length + 1 : 1}. Assert the error message</strong>
                  }
                  { testCase.is_positive ?
                    testCase.result_elements?.length ?
                      <>&quot;{testCase.result_elements?.[0].text}&quot;</> :
                      testCase.common_result === "SUCCESS" ? "Login successful" : "Login failed" :
                    testCase.common_result === "SUCCESS" ? <>&quot;{testCase.result_elements?.[0].text}&quot;</> : ""
                  }
                </Box>
              ) : null }
            </Box>
            <Box className="testCaseDetailsTabContentVideo">
              <VideoPlayer
                ref={videoRef}
                videoSrc={testCase?.videoUrl}
              />
            </Box>
          </>
        ) : testCase && testCase.status === "Draft" ? (
          <>
            <Box className="testCaseDetailsTabContentSteps">
              {testCase?.steps ? (
                <Steps
                  steps={testCase.steps}
                  handleSeek={handleSeek}
                  isDraft={testCase.status === "Draft"}
                />
              ) : null }
            </Box>
            <Box className="testCaseDetailsTabContentInfo">
              <Alert
                severity="info"
                sx={{ wordBreak: "break-word", mt: 1 }}
              >
                There will be generated a video of the test execution after your approval.
              </Alert>
            </Box>
          </>
        ) : (
          <Box sx={{ mt: 2, width: "100%" }}>
            <Typography mb={1}>Test case running...</Typography>
            <LinearProgress color="secondary" />
          </Box>
        )}
      </Box>
      <DeleteTestCasesConfirmationModal
        testCase={testCase}
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
      />
    </Box>
  )
}

export default DetailsTab