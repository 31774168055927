import { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { Box, Typography, Tooltip, IconButton, Tab, LinearProgress, Alert } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { socketEventEmitter } from '../socket'
import { useProject } from '../context/ProjectContext'
import PageService from '../services/PageService'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import StatsSummary from '../components/StatsSummary'
import VideoPlayer from '../components/VideoPlayer'
import RunSteps from '../components/testRun/RunSteps'
import TestRunActions from '../components/testRun/TestRunActions'
import { calculateRunTime } from '../utils/calculateRunTime'

const TestRun = () => {
  const { id } = useParams()
  const { selectedProject } = useProject()
  const videoRef = useRef(null)
  const navigate = useNavigate()

  const [testRun, setTestRun] = useState(null)
  const [activeTabValue, setActiveTabValue] = useState('1')
  const [activeStep, setActiveStep] = useState(null)
  const [videoTime, setVideoTime] = useState(0)
  const [pageToggler, setPageToggler] = useState(false)

  useEffect(() => {
    if (testRun && selectedProject?.id && testRun.projectId !== selectedProject.id) {
      navigate(`/pages`)
    }

    const getTestRun = async () => {
      try {
        const testRun = await PageService.getTestRunById(id)
        setTestRun(testRun)
        setActiveStep(testRun?.steps[0]?.id)
      } catch (error) {
        console.error('Error fetching test run:', error)
      }
    }

    getTestRun()

    const handleTaskCompleted = async (data) => {
      if (data.type === 'Test Case' && data?.testCaseRunId === id) {
        setPageToggler(!pageToggler)
      }
    }

    const handleTaskFailed = async (data) => {
      if (data.type === 'Test Case' && data?.testCaseRunId === id) {
        setPageToggler(!pageToggler)
      }
    }

    socketEventEmitter.on('taskCompleted', handleTaskCompleted)
    socketEventEmitter.on('taskFailed', handleTaskFailed)

    return () => {
      socketEventEmitter.off('taskCompleted', handleTaskCompleted)
      socketEventEmitter.off('taskFailed', handleTaskFailed)
    }
    // eslint-disable-next-line
  }, [id, selectedProject?.id, pageToggler])

  useEffect(() => {
    if (activeTabValue === '1' && videoRef.current) {
      videoRef.current.currentTime = videoTime
    }
  }, [activeTabValue, videoTime])

  const formatDate = (date) => {
    const formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hourCycle: 'h23'
    })

    return formattedDate
  }

  const handleOpenLink = () => {
    window.open(testRun.pageUrl, '_blank', 'noopener,noreferrer')
  }

  const handleRunTestCase = async () => {
    try {
      const res = await PageService.runTestCase(testRun?.testCaseId)
      navigate(`/test-run/${res[0].testCaseRunId}`)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSeek = (time) => {
    if (videoRef.current) {
      videoRef.current.currentTime = time
      // videoRef.current.play()
    }
  }

  const handleChangeActiveTab = (event, newValue) => {
    if (activeTabValue === '1' && videoRef.current) {
      setVideoTime(videoRef.current.currentTime)
    }
    setActiveTabValue(newValue)
  }

  const mapLogSeverityToAlert = (severity) => {
    const severityMap = {
      INFO: 'info',
      WARNING: 'warning',
      ERROR: 'error',
      DEBUG: 'success',
    }

    return severityMap[severity.toUpperCase()] || 'info'
  }

  return (
    <Box className="testRun">
      <Helmet>
        <title>{`Treegress - ${selectedProject.name} ${testRun?.pageLabel} ${testRun?.name} for ${testRun?.testCaseName}`}</title>
      </Helmet>
      <Header title={testRun?.pageLabel} />
      <Box sx={{ display: "flex", pt: "56px" }}>
        <Sidebar />
        <Box className="testRunContentContainer">
          <Box sx={{ display: "flex" }}>
            <StatsSummary
              title="Queued"
              value={formatDate(new Date(testRun?.queued_at))}
              color="primary"
            />
            <StatsSummary
              title="Started"
              value={formatDate(new Date(testRun?.started_at))}
              color="primary"
            />
            <StatsSummary
              title="Run Time"
              value={
                testRun?.started_at && testRun?.updated_at
                  ? calculateRunTime(
                    testRun.started_at,
                    testRun.updated_at
                  )
                  : "N/A"
              }
              color="primary"
            />
            <StatsSummary
              title="Issues"
              value={
                testRun?.issuesTotal || testRun?.issuesTotal === 0
                  ? testRun.issuesTotal
                  : null
              }
              color={testRun?.issuesTotal ? "#D32F2F" : "#198754"}
            />
            <StatsSummary
              title="Steps Executed"
              value={
                testRun?.stepsExecuted && testRun?.stepsTotal
                  ? `${testRun?.stepsExecuted} of ${testRun?.stepsTotal}`
                  : null
              }
              color={testRun?.issuesTotal ? "#D32F2F" : "#198754"}
            />
          </Box>
          <Box className="testRunContent">
            <Box className="testRunTitle">
              <Typography variant="h6" color="primary">
                {testRun?.name} {testRun?.testCaseName}
              </Typography>
              <TestRunActions
                handleRunTestCase={handleRunTestCase}
              />
            </Box>
            <Box className="testRunPageUrl">
              <Typography variant="body1" color="primary" className="testRunPageLabel">
                Url:&nbsp;
              </Typography>
              <Typography variant="body1" color="primary">
                {testRun?.pageUrl}
              </Typography>
              <Tooltip
                title="Open in a new tab"
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "primary.main",
                    },
                  },
                }}
              >
                <IconButton
                  sx={{ ml: 0.5 }}
                  onClick={handleOpenLink}
                >
                  <OpenInNewIcon color="primary" />
                </IconButton>
              </Tooltip>
            </Box>
            <Box className="testRunPageDescription">
              <Typography variant="body1" color="primary" className="testRunPageLabel">
                Description:&nbsp;
              </Typography>
              <Typography variant="body1" color="primary">
                {testRun?.description}
              </Typography>
            </Box>
            <Box className="testRunMainContent">
              {testRun && (testRun.status === "COMPLETED" || testRun.status === "FAILED") ? (
                <>
                  <Box className="testRunMainContentSteps">
                    <Typography variant="body1" color="primary" className="testRunPageLabel" mb={1}>
                      Steps:
                    </Typography>
                    {testRun.steps ? (
                      <RunSteps
                        steps={testRun.steps}
                        activeStep={activeStep}
                        setActiveStep={setActiveStep}
                        handleSeek={handleSeek}
                      />
                    ) : null}
                    <Box
                      className="testRunAssertion"
                      sx={{
                        backgroundColor: testRun.common_result === "SUCCESS" ? "rgba(25, 135, 84, 0.1)" : "rgba(211, 47, 47, 0.1)",
                      }}
                    >
                      <Box>
                        { testRun.is_positive ?
                          <strong style={{ display: 'block' }}>{testRun?.steps ? testRun?.steps.length + 1 : 1}. Assertion: </strong> :
                          testRun.common_result === "SUCCESS" ?
                            <strong style={{ display: 'block' }}>{testRun?.steps ? testRun?.steps.length + 1 : 1}. Assert the error message: </strong> :
                            <strong style={{ display: 'block' }}>{testRun?.steps ? testRun?.steps.length + 1 : 1}. Assert the error message</strong>
                        }
                        { testRun.is_positive ?
                          testRun.result_elements?.length ?
                            <>&quot;{testRun.result_elements?.[0].text}&quot;</> :
                            testRun.common_result === "SUCCESS" ? "Login successful" : "Login failed" :
                          testRun.common_result === "SUCCESS" ? <>&quot;{testRun.result_elements?.[0].text}&quot;</> : ""
                        }
                      </Box>
                      {testRun.common_result === "SUCCESS" ? (
                        <CheckCircleOutlineOutlinedIcon color="success" />
                      ) : (
                        <HighlightOffOutlinedIcon color="error" />
                      )}
                    </Box>
                  </Box>
                  <Box className="testRunMainContentTabs">
                    <TabContext value={activeTabValue}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "rgba(45,67,86,0.5)",
                        }}
                      >
                        <TabList onChange={handleChangeActiveTab}>
                          <Tab
                            label="Video"
                            value="1"
                            sx={{
                              fontSize: "1.25rem",
                              textTransform: "capitalize",
                            }}
                          />
                          <Tab
                            label="Log"
                            value="2"
                            sx={{
                              fontSize: "1.25rem",
                              textTransform: "capitalize",
                            }}
                          />
                        </TabList>
                      </Box>
                      <TabPanel
                        value="1"
                        sx={{ p: "16px 0" }}
                      >
                        <Box className="testRunMainContentVideoContainer">
                          <Box sx={{ width: "100%" }}>
                            <VideoPlayer
                              ref={videoRef}
                              videoSrc={
                                testRun?.videoUrl
                              }
                            />
                          </Box>
                        </Box>
                      </TabPanel>
                      <TabPanel
                        value="2"
                        sx={{ p: "16px 0" }}
                      >
                        <Box className="testRunMainContentLog">
                          {testRun?.steps && testRun.steps.find((step) => step.id === activeStep)?.logs.map((log) => (
                            <Alert
                              key={log.id}
                              severity={mapLogSeverityToAlert(log.level)}
                              sx={{
                                wordBreak: "break-word",
                                mb: 2,
                              }}
                            >
                              {log.message}
                              <br />
                              <br />
                            </Alert>
                          ))}
                        </Box>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </>
              ) : (
                <Box sx={{ mt: 2, width: "100%" }}>
                  <Typography mb={1}>
                    Test case running...
                  </Typography>
                  <LinearProgress color="secondary" />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TestRun