import ApiService from "./ApiService"

const PageService = {
  getPagesByProjectId: async function (projectId) {
    const endpoint = `/pages/project/${projectId}`
    return await ApiService.get(endpoint)
  },
  getAllPagesByProjectId: async function (projectId) {
    const endpoint = `/pages/project/all/${projectId}`
    return await ApiService.get(endpoint)
  },
  getPageById: async function (pageId) {
    const endpoint = `/pages/${pageId}`
    return await ApiService.get(endpoint)
  },
  createPage: async function (projectID, pageName, projectURL, parentID) {
    const endpoint = `/pages`
    return await ApiService.post(endpoint, { label: pageName ? pageName : null, url: projectURL, project_id: projectID, parent_id: parentID })
  },
  updatePages: async function (projectsData) {
    const endpoint = `/pages`
    return await ApiService.put(endpoint, projectsData)
  },
  deletePages: async function (pageIds) {
    const endpoint = '/pages/delete'
    return await ApiService.post(endpoint, { ids: pageIds })
  },
  getAllTestCasesByPageId: async function (pageId) {
    const endpoint = `/test-case/page/${pageId}/?pageSize=1000`
    return await ApiService.get(endpoint)
  },
  discoverTestCasesByPageId: async function (pageId) {
    const endpoint = `/test-case/discover/${pageId}`
    return await ApiService.post(endpoint)
  },
  getTestCaseById: async function (testCaseId) {
    const endpoint = `/test-case/${testCaseId}`
    return await ApiService.get(endpoint)
  },
  deleteTestCases: async function (testCaseIds) {
    const endpoint = '/test-case/delete'
    return await ApiService.post(endpoint, { ids: testCaseIds })
  },
  deleteTestRuns: async function (testRunIds) {
    const endpoint = '/test-case/delete-runs'
    return await ApiService.post(endpoint, { ids: testRunIds })
  },
  getAllTestRunsByTestCaseId: async function (testCaseId) {
    const endpoint = `/test-case/runs/${testCaseId}/?pageSize=1000`
    return await ApiService.get(endpoint)
  },
  runTestCase: async function (testCaseId) {
    const endpoint = '/test-case/run'
    return await ApiService.post(endpoint, { ids: [testCaseId] })
  },
  runTestCases: async function (testCaseIds) {
    const endpoint = '/test-case/run'
    return await ApiService.post(endpoint, { ids: testCaseIds })
  },
  getTestRunById: async function (testRunId) {
    const endpoint = `/test-case/run/${testRunId}`
    return await ApiService.get(endpoint)
  },
  deleteTestRunById: async function (testRunId) {
    const endpoint = `/test-case/run/${testRunId}`
    return await ApiService.delete(endpoint)
  },
}

export default PageService