import { Box, Button } from '@mui/material'

const TestCaseActions = ({ testCase, handleOpenDeleteDialog, handleRunTestCase }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        id='delereTestCaseButton'
        variant="outlined"
        color="error"
        onClick={handleOpenDeleteDialog}
        sx={{ width: '160px', textTransform: 'none', mr: 2 }}
      >
        Delete
      </Button>
      <Button
        id='runTestCaseButton'
        variant="contained"
        color="secondary"
        onClick={handleRunTestCase}
        sx={{ width: '160px', textTransform: 'none', mr: 2 }}
      >
        { testCase?.status === "Draft" ? "Approve" : "Run" }
      </Button>
    </Box>
  )
}

export default TestCaseActions