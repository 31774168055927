import { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, CircularProgress } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import PageService from '../../../../services/PageService'

const DeleteTestRunsConfirmationModal = ({
  deleteOpen,
  setDeleteOpen,
  tabToggler,
  setTabToggler,
  testRunsToDelete,
  setCheckedTestRuns
}) => {
  const [loading, setLoading] = useState(false)

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDeleteTestCases = async () => {
    setLoading(true)
    try {
      if (testRunsToDelete?.length === 1) {
        await PageService.deleteTestRunById(...testRunsToDelete)
        setTabToggler(!tabToggler)
      } else {
        setCheckedTestRuns([])
      }
      // setProjectsToggler(!projectsToggler)
      setDeleteOpen(false)
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={deleteOpen}
      onClose={handleCloseDeleteDialog}
    >
      <Box sx={{ p: '32px' }}>
        <DialogTitle sx={{ p: 0, mb: 1 }}>
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <DialogContentText>
            Are you sure you want to delete Test Run(s)? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ p: 0, mt: 3 }}>
          <Button onClick={handleCloseDeleteDialog} color="primary" variant="outlined">
            Cancel
          </Button>
          {loading ? (
            <LoadingButton
              loading
              variant="contained"
              color="secondary"
              loadingIndicator={<CircularProgress color="white" size={16} />}
              sx={{ width: '86px', height: '36px', textTransform: 'none' }}
            />
          ) : (
            <Button onClick={handleDeleteTestCases} color="secondary" variant="contained" autoFocus>
              Delete
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default DeleteTestRunsConfirmationModal