import { createContext, useContext, useState, useEffect } from 'react'
import UserService from '../services/UserService'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const [userId, setUserId] = useState(null)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const token = localStorage.getItem('accessToken')
    const savedUserId = localStorage.getItem('treegressUserId')

    const getUserData = async () => {
      try {
        if (token) {
          if (savedUserId) {
            setIsAuthenticated(true)
            setUserId(savedUserId)
          }

          const profile = await UserService.getProfile()
          setUser(profile)
        }
      } catch (error) {
        console.error('Failed to fetch profile:', error)
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        setIsAuthenticated(false)
        setUser(null)
      } finally {
        setLoading(false)
      }
    }

    getUserData()
  }, [])

  const login = async (accessToken, refreshToken, userId) => {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('treegressUserId', userId)
    setIsAuthenticated(true)
    setUserId(userId)
    try {
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('treegressUserId', userId)

      const profile = await UserService.getProfile()
      setIsAuthenticated(true)
      setUser(profile)
    } catch (error) {
      console.error('Failed to log in:', error)
      logout()
    }
  }

  const logout = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('treegressUserId')
    localStorage.removeItem('selectedProject')
    setIsAuthenticated(false)
    setUserId(null)
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, userId, user }}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}