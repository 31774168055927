import ApiService from "./ApiService"

const TaskService = {
  getTasksByProjectId: async function (page=1, projectId, sortBy, sortDirection) {
    const endpoint = `/task/project/${projectId}?page=${page}&sort=${sortBy}&sort_direction=${sortDirection}`
    return await ApiService.get(endpoint)
  },
  getAllTasksByProjectId: async function (projectId) {
    const endpoint = `/task/project/${projectId}?pageSize=5000`
    return await ApiService.get(endpoint)
  },
  getLoadStatus: async function () {
    const endpoint = '/task/load'
    return await ApiService.get(endpoint)
  },
  stopTaskById: async function (taskId) {
    const endpoint = `/task/stop/${taskId}`
    return await ApiService.get(endpoint)
  },
  rerunTaskById: async function (taskId) {
    const endpoint = `/task/rerun/${taskId}`
    return await ApiService.get(endpoint)
  },
  deleteTasksByIds: async function (taskIds) {
    const endpoint = '/task/delete'
    return await ApiService.post(endpoint, { taskIds })
  },
}

export default TaskService