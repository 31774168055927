import { Box, Button, CircularProgress } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

const TestCasesActions = ({
  draftTestCases,
  checkedTestCases,
  handleApproveTestCases,
  handleRunTestCases,
  handleRunAllTestCases,
  isGeneratingTestCasesLoading,
  handleGenerateTestCases,
  handleOpenDeleteDialog
}) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      { checkedTestCases.length ?
        <Button
          id='delereTestCasesButton'
          variant="outlined"
          color="error"
          onClick={() => handleOpenDeleteDialog()}
          sx={{ width: '168px', textTransform: 'none', mr: 2 }}
        >
          Delete Test Cases
        </Button> : null
      }
      { !checkedTestCases.length && draftTestCases.length ?
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleApproveTestCases}
          sx={{ width: '216px', textTransform: 'none', mr: 2 }}
        >
          Approve All Test Cases
        </Button>
        : null
      }
      { checkedTestCases.length &&
        draftTestCases.length &&
        checkedTestCases.every(id => draftTestCases.includes(id)) ?
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleApproveTestCases}
          sx={{ width: '252px', textTransform: 'none', mr: 2 }}
        >
          Approve Selected Test Cases
        </Button>
        : null
      }
      { checkedTestCases.length && !checkedTestCases.every(id => draftTestCases.includes(id)) ?
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleRunTestCases}
          sx={{ width: '216px', textTransform: 'none', mr: 2 }}
        >
          Run Selected Test Cases
        </Button> :
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleRunAllTestCases}
          sx={{ width: '216px', textTransform: 'none', mr: 2 }}
        >
          Run All Test Cases
        </Button>
      }
      { isGeneratingTestCasesLoading ?
        <LoadingButton
          loading
          variant="contained"
          color="secondary"
          loadingIndicator={<CircularProgress color="white" size={16} />}
          sx={{ width: '216px', height: '36px', textTransform: 'none', mr: 2 }}
        /> :
        <Button onClick={handleGenerateTestCases} variant="contained" color="secondary" sx={{ width: '216px', textTransform: 'none', mr: 2 }}>
          Generate Test Cases
        </Button>
      }
    </Box>
  )
}

export default TestCasesActions