import { useNavigate } from 'react-router-dom'
import { TableCell, TableRow, Box, Checkbox, IconButton, Tooltip } from '@mui/material'
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { formatDate } from '../../../../utils/formatDate'

const TestCasesListItem = ({ testCase, checkedTestCases, handleCheckboxChange, handleOpenDeleteDialog }) => {
  const navigate = useNavigate()

  const statusStyles = {
    'Draft': { backgroundColor: '#acadad', color: '#fff' },
    'PENDING': { backgroundColor: '#6D6E6F', color: '#fff' },
    'RUNNING': { backgroundColor: '#4574D2', color: '#fff' },
    'COMPLETED': { backgroundColor: '#198754', color: '#fff' },
    'FAILED': { backgroundColor: '#D32F2F', color: '#fff' },
    'CANCELLED': { backgroundColor: '#F8DF73', color: '#2D4356' }
  }

  const handleOpenTestCase = () => {
    navigate(`/test-case/${testCase.id}`)
  }

  return (
    <TableRow key={testCase.id}>
      <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={checkedTestCases.includes(testCase.id)}
          onChange={() => handleCheckboxChange(testCase.id)}
        />
      </TableCell>
      <TableCell>{testCase.name}</TableCell>
      <TableCell>
        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
          <Box
            variant="contained"
            size="small"
            sx={{
              ...statusStyles[testCase.status],
              display: 'flex',
              justifyContent: 'center',
              width: '150px',
              minWidth: '120px',
              fontSize: '14px',
              borderRadius: '16px',
              p: '4px 16px',
              ml: 2,
              textTransform: 'capitalize'
            }}
          >
            {testCase.status.toLowerCase()}
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Box sx={{ ml: 2 }}>
          {formatDate(testCase.created_at)}
        </Box>
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Box sx={{ ml: 2 }}>
          { testCase.updated_at ? formatDate(testCase.updated_at) : 'N/A'}
        </Box>
      </TableCell>
      <TableCell sx={{ minWidth: '120px', textAlign: 'center' }}>
        { testCase.status === 'Draft' || testCase.status === 'FAILED' || testCase.status === 'COMPLETED' ?
          <Tooltip
            title="Open Test Case"
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: 'primary.main'
                },
              },
            }}
          >
            <IconButton sx={{ mr: 1 }} onClick={(e) => {e.stopPropagation(); handleOpenTestCase()}}>
              <PlayCircleFilledWhiteOutlinedIcon color='primary' />
            </IconButton>
          </Tooltip> :
          <Box sx={{ display: 'inline-flex', verticalAlign: 'middle', mr: 1, p: 1, cursor: 'default', opacity: 0.3 }} onClick={(e) => e.stopPropagation()}>
            <PlayCircleFilledWhiteOutlinedIcon />
          </Box>
        }
        <Tooltip
          title="Delete Test Case"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              },
            },
          }}
        >
          <IconButton onClick={(e) => {e.stopPropagation(); handleOpenDeleteDialog(testCase?.id)}}>
            <DeleteOutlineOutlinedIcon color='primary' />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default TestCasesListItem