import { TableHead, TableRow, TableCell, Checkbox, Box, IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SortingIcon from '../SortingIcon'

const TableHeader = ({
  isHeaderCheckboxChecked,
  handleCheckAllCurrentPage,
  handleUncheckAllCurrentPage,
  handleClickCheckAllDropdown,
  handleCloseCheckAllDropdown,
  anchorEl,
  openAnchorEl,
  handleSortChange,
  sortConfig
}) => {


  return (
    <TableHead sx={{ borderBottom: '2px solid #D7E2EC' }}>
      <TableRow>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              sx={{ ml: '17px', p: 0 }}
              checked={isHeaderCheckboxChecked}
              onChange={(e) => {
                const allChecked = e.target.checked
                if (allChecked) {
                  handleCheckAllCurrentPage()
                } else {
                  handleUncheckAllCurrentPage()
                }
              }}
            />
            <IconButton
              onClick={handleClickCheckAllDropdown}
              size="small"
              sx={{ p: 0 }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={openAnchorEl}
              onClose={handleCloseCheckAllDropdown}
            >
              <MenuItem onClick={handleCheckAllCurrentPage} sx={{ minWidth: '100px' }}>All</MenuItem>
              <MenuItem onClick={handleUncheckAllCurrentPage}>None</MenuItem>
            </Menu>
          </Box>
        </TableCell>
        <TableCell sx={{ width: '180px', minWidth: '180px', cursor: 'pointer' }} onClick={() => handleSortChange('task_type')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'task_type'}/>
            Task Type
          </Box>
        </TableCell>
        <TableCell sx={{ minWidth: '220px', cursor: 'pointer' }} onClick={() => handleSortChange('page_name')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'page_name'}/>
            Page Name
          </Box>
        </TableCell>
        <TableCell sx={{ width: '248px', minWidth: '248px', cursor: 'pointer' }} onClick={() => handleSortChange('started_at')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'started_at'}/>
            Started
          </Box>
        </TableCell>
        <TableCell sx={{ width: '140px', minWidth: '140px', cursor: 'pointer' }} onClick={() => handleSortChange('run_time')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'run_time'}/>
            Run Time
          </Box>
        </TableCell>
        <TableCell sx={{ width: '140px', minWidth: '140px', cursor: 'pointer' }} onClick={() => handleSortChange('status')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'status'}/>
            Status
          </Box>
        </TableCell>
        <TableCell sx={{ minWidth: '120px' }}>
          <Box className="tableCellContentWithBorder">
              Details
          </Box>
        </TableCell>
        <TableCell sx={{ minWidth: '186px', cursor: 'pointer' }} onClick={() => handleSortChange('error')}>
          <Box className="tableCellContentWithBorder">
            <SortingIcon sortConfig={sortConfig} currentKey={'error'}/>
            Error
          </Box>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
}

export default TableHeader