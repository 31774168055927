import { Box, Typography, TableBody, TableRow, TableCell } from '@mui/material'
import PageList from './PageList'

const HierarchicalTableContent = ({ sitemap, prevSitemap, checkedPages, setCheckedPages, handleOpenDeleteDialog }) => {
  return (
    <TableBody>
      <TableRow >
        <TableCell colSpan={8} sx={{ p: 0, borderBottom: 'none' }}>
          {sitemap && sitemap.length ?
            <PageList
              sitemap={sitemap}
              prevSitemap={prevSitemap}
              checkedPages={checkedPages}
              setCheckedPages={setCheckedPages}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
            /> :
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Typography variant="body1" align="center">
                Website Mapping is nearly ready, so you’ll soon be able to view and select pages for scanning.
              </Typography>
            </Box>
          }
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

export default HierarchicalTableContent