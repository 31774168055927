import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useProject } from '../context/ProjectContext'
import { socketEventEmitter } from '../socket'
import { Box } from '@mui/material'
import ProjectService from '../services/ProjectService'
import PageService from '../services/PageService'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import AddPageModal from '../components/modals/AddPageModal'
import PagesContent from '../components/pages/PagesContent'

const Pages = () => {
  const { selectedProject } = useProject()

  const [prevSitemap, setPrevSitemap] = useState([])
  const [sitemap, setSitemap] = useState([])
  const [addPageModalOpen, setAddPageModalOpen] = useState(false)
  const [pageURL, setPageURL] = useState('')
  const [pagesToggler, setPagesToggler] = useState(false)
  const [prevPages, setPrevPages] = useState([])
  const [pages, setPages] = useState([])

  useEffect(() => {
    const fetchSitemap = async () => {
      try {
        const oldSiteMap = [...sitemap]
        const res = await ProjectService.getProjectSitemapByProjectId(selectedProject.id)
        setPrevSitemap(oldSiteMap)
        setSitemap([res])
      } catch (error) {
        console.log(error.response.data.error)
      }
    }

    const fetchPages = async () => {
      try {
        const oldPages = [...pages]
        const pagesData = await PageService.getPagesByProjectId(selectedProject.id)
        setPrevPages(oldPages)
        setPages(pagesData)
      } catch (error) {
        console.log(error.response.data.error)
      }
    }

    const fetchData = async () => {
      await fetchSitemap()
      await fetchPages()
    }

    if (selectedProject?.id) {
      fetchData()
    }

    const handleTaskCompleted = async () => {
      await fetchData()
    }

    socketEventEmitter.on('taskCompleted', handleTaskCompleted)

    return () => {
      socketEventEmitter.off('taskCompleted', handleTaskCompleted)
    }
  // eslint-disable-next-line
  }, [selectedProject?.id, pagesToggler])

  const handleAddPageOpen = () => {
    setAddPageModalOpen(true)
  }

  return (
    <Box className="pages">
      <Helmet>
        <title>{`Treegress - ${selectedProject?.name} Pages`}</title>
      </Helmet>
      <Header title="Pages"/>
      <Box sx={{ display: 'flex', pt: '56px' }}>
        <Sidebar />
        <Box className="discoveryMainContainer">
          <PagesContent
            projectID={selectedProject?.id}
            pagesList={pages}
            prevPagesList={prevPages}
            sitemap={sitemap}
            prevSitemap={prevSitemap}
            handleAddPageOpen={handleAddPageOpen}
            pagesToggler={pagesToggler}
            setPagesToggler={setPagesToggler}
          />
        </Box>
      </Box>
      <AddPageModal
        projectID={selectedProject ? selectedProject.id : null}
        pages={pages}
        sitemap={sitemap}
        addPageModalOpen={addPageModalOpen}
        setAddPageModalOpen={setAddPageModalOpen}
        pageURL={pageURL}
        setPageURL={setPageURL}
        pagesToggler={pagesToggler}
        setPagesToggler={setPagesToggler}
      />
    </Box>
  )
}

export default Pages