import { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, CircularProgress, Alert } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import PageService from '../../services/PageService'

const DeletePagesConfirmationModal = ({
  deleteOpen,
  setDeleteOpen,
  pagesToggler,
  setPagesToggler,
  pagesToDelete,
  setPagesToDelete,
  setCheckedPages
}) => {
  const [loading, setLoading] = useState(false)

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDeletePages = async () => {
    setLoading(true)
    try {
      await PageService.deletePages(pagesToDelete)
      setCheckedPages([])
      setPagesToDelete([])
      setPagesToggler(!pagesToggler)
      setDeleteOpen(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={deleteOpen}
      onClose={handleCloseDeleteDialog}
    >
      <Box sx={{ p: '32px' }}>
        <DialogTitle sx={{ p: 0, mb: 1 }}>
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <DialogContentText>
            Are you sure you want to delete page(s)? This action cannot be undone.
          </DialogContentText>
          <Alert severity='warning' sx={{ mt: 1 }}>
            <strong>Warning: </strong>
            Deleting a page with nested pages in the hierarchical structure of sitemap will also delete all its nested pages. Please review the pages carefully before proceeding.
          </Alert>
        </DialogContent>
        <DialogActions sx={{ p: 0, mt: 3 }}>
          <Button onClick={handleCloseDeleteDialog} color="primary" variant="outlined">
            Cancel
          </Button>
          {loading ? (
            <LoadingButton
              loading
              variant="contained"
              color="secondary"
              loadingIndicator={<CircularProgress color="white" size={16} />}
              sx={{ width: '86px', height: '36px', textTransform: 'none' }}
            />
          ) : (
            <Button onClick={handleDeletePages} color="secondary" variant="contained" autoFocus>
              Delete
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default DeletePagesConfirmationModal