import { useState, useEffect } from 'react'
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, Checkbox, Menu, MenuItem, IconButton, Tooltip } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SortingIcon from '../../../SortingIcon'
import PageService from '../../../../services/PageService'
import { socketEventEmitter } from '../../../../socket'
import TestRunsListItem from './TestRunsListItem'
import TestRunsActions from './TestRunsActions'
import DeleteTestRunsConfirmationModal from './DeleteTestRunsConfirmationModal'
import { sortTableItems } from '../../../../utils/sortTableItems'

const RunsTab = ({ testCase }) => {
  const [testRuns, setTestRuns] = useState([])
  const [testRunsTotal, setTestRunsTotal] = useState([])
  const [checkedTestRuns, setCheckedTestRuns] = useState([])
  const [testRunsToDelete, setTestRunsToDelete] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false)
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' })
  const [tabToggler, setTabToggler] = useState(false)
  const [page, setPage] = useState(1)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const openAnchorEl = Boolean(anchorEl)
  const testRunsPerPage = 10
  const startItem = (page - 1) * testRunsPerPage + 1
  const endItem = Math.min(page * testRunsPerPage, testRunsTotal)

  useEffect(() => {
    const getTestRuns = async () => {
      try {
        const testRunsData = await PageService.getAllTestRunsByTestCaseId(testCase?.id)
        setTestRuns(testRunsData.runs)
        setTestRunsTotal(testRunsData.total)
      } catch (error) {
        console.error('Error fetching test runs:', error)
      }
    }

    getTestRuns()

    const handleTaskCompleted = async (data) => {
      if (data.testCaseId === testCase.id && data.type === 'Test Case') {
        setTabToggler(!tabToggler)
      }
    }

    const handleTaskFailed = async (data) => {
      if (data.testCaseId === testCase.id && data.type === 'Test Case') {
        setTabToggler(!tabToggler)
      }
    }

    socketEventEmitter.on('taskCompleted', handleTaskCompleted)
    socketEventEmitter.on('taskFailed', handleTaskFailed)

    return () => {
      socketEventEmitter.off('taskCompleted', handleTaskCompleted)
      socketEventEmitter.off('taskFailed', handleTaskFailed)
    }
    // eslint-disable-next-line
  }, [tabToggler])

  const handleDeleteTestRuns = async () => {
    try {
      await PageService.deleteTestRuns(checkedTestRuns)
      setCheckedTestRuns([])
      setTabToggler(!tabToggler)
    } catch (error) {
      console.log(error)
    }
  }

  const handleOpenLink = () => {
    window.open(testCase.pageUrl, '_blank', 'noopener,noreferrer')
  }

  const handleOpenDeleteDialog = (testRunID) => {
    if (testRunID) {
      setTestRunsToDelete([testRunID])
    } else {
      setTestRunsToDelete(checkedTestRuns)
    }
    setDeleteOpen(true)
  }

  const handleClickCheckAllDropdown = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseCheckAllDropdown = () => {
    setAnchorEl(null)
  }

  const handleCheckAllCurrentPage = () => {
    const currentPageTestRunIds = paginatedTestRuns.map(testRun => testRun.id)
    setCheckedTestRuns(prevCheckedTestRuns => [
      ...new Set([...prevCheckedTestRuns, ...currentPageTestRunIds])
    ])
    setIsHeaderCheckboxChecked(true)
    handleCloseCheckAllDropdown()
  }

  const handleUncheckAllCurrentPage = () => {
    const currentPageTestRunIds = paginatedTestRuns.map(testRun => testRun.id)
    setCheckedTestRuns(prevCheckedTestRuns =>
      prevCheckedTestRuns.filter(testRunId => !currentPageTestRunIds.includes(testRunId))
    )
    setIsHeaderCheckboxChecked(false)
    handleCloseCheckAllDropdown()
  }

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const handleCheckboxChange = (id) => {
    setCheckedTestRuns(prevCheckedTestRuns => {
      if (prevCheckedTestRuns.includes(id)) {
        return prevCheckedTestRuns.filter(testRunId => testRunId !== id)
      } else {
        return [...prevCheckedTestRuns, id]
      }
    })
  }

  const handleSortChange = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }))
  }

  const sortedTestRuns = sortTableItems(testRuns, sortConfig)

  const paginatedTestRuns = sortedTestRuns.slice((page - 1) * testRunsPerPage, page * testRunsPerPage)

  return (
    <Box className="testCaseRunsTab">
      <Box className="testCaseRunsTabTitle">
        <Typography variant="h6" color="primary">{testCase?.testCaseName}</Typography>
        <TestRunsActions checkedTestRuns={checkedTestRuns} handleDeleteTestRuns={handleDeleteTestRuns}/>
      </Box>
      <Box className="testCaseRunsTabPageUrl">
        <Typography variant="body1" color="primary">{testCase?.pageUrl}</Typography>
        <Tooltip
          title="Open in a new tab"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              },
            },
          }}
        >
          <IconButton sx={{ ml: 0.5 }} onClick={handleOpenLink}>
            <OpenInNewIcon color='primary'/>
          </IconButton>
        </Tooltip>
      </Box>
      <TableContainer className='testCaseRunsTableContainer' component={Paper} sx={{ boxShadow: 'none' }}>
        <Table className='testRunsTable' stickyHeader>
          <TableHead sx={{ borderBottom: '2px solid #D7E2EC' }}>
            <TableRow>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    sx={{ ml: '17px', p: 0 }}
                    checked={isHeaderCheckboxChecked}
                    onChange={(e) => {
                      const allChecked = e.target.checked
                      if (allChecked) {
                        handleCheckAllCurrentPage()
                      } else {
                        handleUncheckAllCurrentPage()
                      }
                    }}
                  />
                  <IconButton
                    onClick={handleClickCheckAllDropdown}
                    size="small"
                    sx={{ p: 0 }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={openAnchorEl}
                    onClose={handleCloseCheckAllDropdown}
                  >
                    <MenuItem onClick={handleCheckAllCurrentPage} sx={{ minWidth: '100px' }}>All</MenuItem>
                    <MenuItem onClick={handleUncheckAllCurrentPage}>None</MenuItem>
                  </Menu>
                </Box>
              </TableCell>
              <TableCell sx={{ minWidth: '100px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('id')}>
                <Box className="tableCellContentWithBorder">
                  <SortingIcon sortConfig={sortConfig} currentKey={'name'}/>
                  Run ID
                </Box>
              </TableCell>
              <TableCell sx={{ minWidth: '250px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('queued_at')}>
                <Box className="tableCellContentWithBorder">
                  <SortingIcon sortConfig={sortConfig} currentKey={'status'}/>
                  Queued
                </Box>
              </TableCell>
              <TableCell sx={{ minWidth: '250px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('started_at')}>
                <Box className="tableCellContentWithBorder">
                  <SortingIcon sortConfig={sortConfig} currentKey={'created_at'}/>
                  Started
                </Box>
              </TableCell>
              <TableCell sx={{ minWidth: '122px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('updated_at')}>
                <Box className="tableCellContentWithBorder">
                  <SortingIcon sortConfig={sortConfig} currentKey={'updated_at'}/>
                  Run Time
                </Box>
              </TableCell>
              <TableCell sx={{ minWidth: '198px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('status')}>
                <Box className="tableCellContentWithBorder">
                  <SortingIcon sortConfig={sortConfig} currentKey={'status'}/>
                  Execution Status
                </Box>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { paginatedTestRuns.length ?
              paginatedTestRuns.map((testRun) => (
                <TestRunsListItem
                  key={testRun.id}
                  testRun={testRun}
                  checkedTestRuns={checkedTestRuns}
                  handleCheckboxChange={handleCheckboxChange}
                  handleOpenDeleteDialog={handleOpenDeleteDialog}
                />
              )) :
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography variant="body1" align="center">
                    No data available. Click on &apos;Generate Test Cases&apos; button.
                  </Typography>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      { testRuns.length ?
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
          <Typography variant="body2" mr={1}>
            {`${startItem}-${endItem} of ${testRunsTotal}`}
          </Typography>
          <Pagination
            count={Math.ceil(testRunsTotal / testRunsPerPage)}
            page={page}
            onChange={handlePageChange}
            shape="rounded"
            color='primary'
          />
        </Box> : null }
      <DeleteTestRunsConfirmationModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        tabToggler={tabToggler}
        setTabToggler={setTabToggler}
        testRunsToDelete={testRunsToDelete}
        setCheckedTestRuns={setCheckedTestRuns}
      />
    </Box>
  )
}

export default RunsTab