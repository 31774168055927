import { useState } from 'react'
import { Box, Button, Menu, MenuItem } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const DropdownButton = ({ title, handleScanPages }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleScanAllPagesButtonClick = () => {
    if (title === "Scan All Pages") {
      handleScanPages(true, false)
    } else {
      handleScanPages()
    }
  }

  const handleScanIncludedPagesButtonClick = () => {
    handleScanPages(false, true)
    handleMenuClose()
  }

  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <Button
        id='scanAllPagesButton'
        className='dropdownButton'
        variant="contained"
        color="secondary"
        onClick={handleScanAllPagesButtonClick}
      >
        {title}
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleMenuOpen}
        sx={{
          width: '30px',
          minWidth: '30px',
          height: '36.5px',
          borderRadius: '0 4px 4px 0',
          border: '1px solid #fff',
          borderWidth: '0 0 0 1px'
        }}
      >
        <ArrowDropDownIcon />
      </Button>
      <Menu
        className="dropdownButtonMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        sx={{
          transform: 'translateX(-8px)',
        }}
      >
        <MenuItem
          id='scanIncludedPagesButton'
          onClick={handleScanIncludedPagesButtonClick}
          sx={{
            height: '36.5px',
            color: 'secondary.main',
            border: '1px solid',
            borderColor: 'secondary.main',
            borderRadius: '4px',
            fontSize: '0.875rem',
            fontWeight: '500',
            px: '21px',
            '&:hover': {
              backgroundColor: 'rgba(69, 116, 210, 0.04)'
            }
          }}
        >
          Scan Included Pages
        </MenuItem>
      </Menu>
    </Box>
  )
}

export default DropdownButton