import { Box, Autocomplete, TextField, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

const SearchBar = ({ pagesList, handleSearch }) => (
  <Box sx={{ width: '322px' }}>
    <Autocomplete
      size="small"
      freeSolo
      onChange={handleSearch}
      options={pagesList}
      getOptionLabel={(page) => page?.label || page.url || ''}
      getOptionKey={page => page.id}
      filterOptions={(options, { inputValue }) =>
        options.filter(
          (option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.url.toLowerCase().includes(inputValue.toLowerCase())
        )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start" sx={{ mr: -0.5 }}>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  </Box>
)

export default SearchBar