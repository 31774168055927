import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view'
import { Box, Typography, Checkbox, FormControlLabel, IconButton, Menu, MenuItem, Tooltip } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { formatDate } from '../../../../utils/formatDate'

const PageList = ({ sitemap, prevSitemap, checkedPages, setCheckedPages, handleOpenDeleteDialog }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentPageId, setCurrentPageId] = useState(null)
  const [newPages, setNewPages] = useState([])
  const [expandedItems, setExpandedItems] = useState([])
  const [tooltipOpen, setTooltipOpen] = useState('')
  const open = Boolean(anchorEl)

  const navigate = useNavigate()

  const getPageIds = (pages) => {
    return pages.flatMap(page => [
      page.id,
      ...(page.children ? getPageIds(page.children) : [])
    ])
  }

  useEffect(() => {
    setExpandedItems(
      sitemap.flatMap(page => [
        page.id,
        ...(page.children ? getPageIds(page.children) : [])
      ])
    )

    if (sitemap.length && prevSitemap.length && sitemap[0].project_id === prevSitemap[0].project_id) {
      const prevPageIds = getPageIds(prevSitemap)
      const currentPageIds = getPageIds(sitemap)

      const newlyAddedPages = currentPageIds.filter(id => !prevPageIds.includes(id))
      setNewPages(newlyAddedPages)
    }
  // eslint-disable-next-line
  }, [sitemap, prevSitemap])

  const handleToggle = (event, pageId) => {
    const isChecked = event.target.checked

    setCheckedPages((prev) =>
      isChecked
        ? [...prev, pageId]
        : prev.filter((id) => id !== pageId)
    )
  }

  const handleClick = (event, pageId) => {
    setAnchorEl(event.currentTarget)
    setCurrentPageId(pageId)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setCurrentPageId(null)
  }

  const handleCheckAllChildren = (page) => {
    const allChildIds = getAllChildIds(page)
    setCheckedPages((prev) => [...new Set([...prev, ...allChildIds])])
    handleClose()
  }

  const handleUncheckAllChildren = (page) => {
    const allChildIds = getAllChildIds(page)
    setCheckedPages((prev) => prev.filter(id => !allChildIds.includes(id)))
    handleClose()
  }

  const getAllChildIds = (page) => {
    let ids = [page.id]
    if (page.children) {
      page.children.forEach((child) => {
        ids = [...ids, ...getAllChildIds(child)]
      })
    }
    return ids
  }

  const handleToggleExpansion = (event, nodeIds) => {
    setExpandedItems(nodeIds)
  }

  const handleRowClick = (pageId) => {
    navigate(`/pages/${pageId}`)
  }

  const handleCopyToClipboard = (pageUrl, pageId) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(pageUrl)
    } else {
      const textArea = document.createElement("textarea")
      textArea.value = pageUrl
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand("copy")
      document.body.removeChild(textArea)
    }

    setTooltipOpen(pageId)
    setTimeout(() => {
      setTooltipOpen('')
    }, 1000)
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  const renderSitemapItems = (sitemap) => (
    sitemap.map(page => {
      const isNew = newPages.includes(page.id)
      const isChecked = checkedPages.includes(page.id)

      return (
        <TreeItem
          key={page.id}
          itemId={page.id}
          sx={{
            pl: 2,
            backgroundColor: isNew || isChecked ? 'rgba(69, 116, 210, 0.1)' : 'inherit'
          }}
          label={
            <FormControlLabel
              className="pageListItem"
              sx={{ width: '100%', mr: 1 }}
              onClick={() => handleRowClick(page.id)}
              control={
                <Checkbox
                  sx={{ ml: 1 , p: 0 }}
                  checked={checkedPages.includes(page.id)}
                  onClick={(event) => event.stopPropagation()}
                  onChange={(event) => handleToggle(event, page.id)}
                />
              }
              label={
                <Box sx={{ width: '100%', minHeight: '46px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    { page.children && page.children.length ? (
                      <>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation()
                            handleClick(event, page.id)
                          }}
                          size="small"
                          sx={{ p: 0 }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={open && currentPageId === page.id}
                          onClose={handleClose}
                        >
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation()
                              handleCheckAllChildren(page)
                            }}
                            sx={{ minWidth: '100px' }}
                          >
                            All
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation()
                              handleUncheckAllChildren(page)
                            }}
                          >
                            None
                          </MenuItem>
                        </Menu>
                      </>
                    ) : null }
                    <Box mt='5px' ml='4px' sx={{ position: 'relative', mt: '5px', ml: 0.5, pr: 3, wordBreak: 'break-word', fontWeight: isNew ? 500 : 400 }}>
                      {page.url}
                      <Tooltip
                        PopperProps={{
                          disablePortal: true,
                          sx: {
                            '& .MuiTooltip-tooltip': {
                              backgroundColor: 'primary.main'
                            },
                          },
                        }}
                        onClose={handleTooltipClose}
                        open={tooltipOpen === page.id}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="Copied to clipboard!"
                      >
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation()
                            handleCopyToClipboard(page.url, page.id)
                          }}
                          sx={{ position: 'absolute', right: -8, top: '50%', transform: 'translateY(-50%)' }}
                        >
                          <ContentCopyIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {page.updated_at ? (
                      <Typography color="primary.main" sx={{ width: '178px', minWidth: '178px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: isNew ? 500 : 400 }}>
                        {formatDate(page.updated_at)}
                      </Typography>
                    ) : (
                      <Typography sx={{ width: '178px', minWidth: '178px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: isNew ? 500 : 400 }}>
                        N/A
                      </Typography>
                    )}
                    {page.last_scanned_at ? (
                      <Typography color="primary.main" sx={{ width: '132px', minWidth: '132px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: isNew ? 500 : 400 }}>
                        {formatDate(page.last_scanned_at)}
                      </Typography>
                    ) : (
                      <Typography sx={{ width: '132px', minWidth: '132px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: isNew ? 500 : 400 }}>
                        Unscanned
                      </Typography>
                    )}
                    {page.passed_ui_test_count !== null ? (
                      <Typography color="success.main" sx={{ width: '126px', minWidth: '126px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: isNew ? 500 : 400 }}>
                        {page.passed_ui_test_count}
                      </Typography>
                    ) : (
                      <Typography sx={{ width: '126px', minWidth: '126px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: isNew ? 500 : 400 }}>
                        N/A
                      </Typography>
                    )}
                    {page.failed_ui_test_count !== null ? (
                      <Typography color="error.main"  sx={{ width: '96px', minWidth: '96px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: isNew ? 500 : 400 }}>
                        {page.failed_ui_test_count}
                      </Typography>
                    ) : (
                      <Typography sx={{ width: '96px', minWidth: '96px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: isNew ? 500 : 400 }}>
                        N/A
                      </Typography>
                    )}
                    <Typography sx={{ minWidth: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                      {page.included ? <DoneIcon color="success" /> : <CloseIcon color="error" />}
                    </Typography>
                    <Typography sx={{ minWidth: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: -1 }}>
                      <IconButton onClick={(e) => {e.stopPropagation(); handleOpenDeleteDialog(page?.id)}}>
                        <DeleteOutlineOutlinedIcon color='primary' />
                      </IconButton>
                    </Typography>
                  </Box>
                </Box>
              }
            />
          }
        >
          {page.children ? renderSitemapItems(page.children) : null}
        </TreeItem>
      )
    })
  )

  return (
    <SimpleTreeView expandedItems={expandedItems} onExpandedItemsChange={handleToggleExpansion}>
      {renderSitemapItems(sitemap)}
    </SimpleTreeView>
  )
}

export default PageList