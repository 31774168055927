import { useState, useEffect } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem, FormControl, InputLabel, FormHelperText, CircularProgress } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import PageService from '../../services/PageService'
import { validateProjectURL } from '../../utils/validateForm'

const AddPageModal = ({
  projectID,
  pages,
  sitemap,
  addPageModalOpen,
  setAddPageModalOpen,
  pageURL,
  setPageURL,
  pagesToggler,
  setPagesToggler,
}) => {
  const [createPageLoading, setCreatePageLoading] = useState(false)
  const [pageURLError, setPageURLError] = useState('')
  const [pageName, setPageName] = useState('')
  const [parentPage, setParentPage] = useState('')
  const [parentPageError, setParentPageError] = useState('')

  useEffect(() => {
    if (sitemap && sitemap.length) {
      setParentPage(sitemap[0].url)
    }
  }, [sitemap])

  const handleClose = () => {
    setAddPageModalOpen(false)
  }

  const handlePageNameChange = (e) => {
    setPageName(e.target.value)
  }

  const handleProjectURLChange = (e) => {
    setPageURL(e.target.value)
    if (pageURLError) {
      setPageURLError('')
    }
  }

  const handleParentPageChange = (event) => {
    setParentPage(event.target.value)
    if (parentPageError) {
      setParentPageError('')
    }
  }

  const handleSave = async () => {
    if (!validateProjectURL(pageURL.trim())) {
      setPageURLError('Invalid URL.')
      return
    } else {
      setPageURLError('')
    }

    if (!parentPage) {
      setParentPageError('Select parent page.')
      return
    } else {
      setPageURLError('')
    }

    try {
      setCreatePageLoading(true)
      const parentPageId = pages.find(page => page.url === parentPage).id
      await PageService.createPage(projectID, pageName, pageURL, parentPageId)
      setPageURL('')
      setParentPage('')
      setCreatePageLoading(false)
      setAddPageModalOpen(false)
      setPagesToggler(!pagesToggler)
    } catch (error) {
      if (error?.response?.data?.error === "Page with the same URL already exists.") {
        setPageURLError(error.response.data.error)
      } else {
        setPageURLError('Invalid URL.')
      }
      setCreatePageLoading(false)
      console.log(error)
    }
  }

  return (
    <Dialog open={addPageModalOpen} onClose={handleClose}>
      <Box sx={{ width: '400px', p: '32px' }}>
        <DialogTitle sx={{ p: 0, mb: 1 }}>Add Page</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <TextField
            label="Page Name"
            type="text"
            fullWidth
            variant="outlined"
            value={pageName}
            onChange={handlePageNameChange}
            sx={{ mt: 1, mb: 2 }}
          />
          <TextField
            margin="dense"
            label="Page URL *"
            type="url"
            fullWidth
            variant="outlined"
            value={pageURL}
            onChange={handleProjectURLChange}
            error={!!pageURLError}
            helperText={pageURLError + ' '}
          />
          <FormControl fullWidth sx={{ mb: 1 }} error={!!parentPageError}>
            <InputLabel error={!!parentPageError}>Parent Page *</InputLabel>
            <Select
              value={parentPage}
              label="Parent Page"
              onChange={handleParentPageChange}
              error={!!parentPageError}
              sx={{
                color: parentPageError ? 'error.main' : 'inherit',
                '& .MuiInputBase-root': {
                  borderColor: parentPageError ? 'error.main' : 'inherit',
                }
              }}
              MenuProps={{
                sx: {
                  maxHeight: '50vh'
                },
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {pages.map((page) => (
                <MenuItem key={page.id} value={page.url} sx={{ maxWidth: '336px', wordBreak: 'break-word' }}>
                  {page.url}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{parentPageError}</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: 0 }}>
          <Button onClick={handleClose} variant="outlined" sx={{ width: '130px', mr: 2 }}>Cancel</Button>
          {createPageLoading ? (
            <LoadingButton
              loading
              variant="contained"
              color="secondary"
              loadingIndicator={<CircularProgress color="white" size={16} />}
              sx={{ width: '130px', textTransform: 'none' }}
            >
              Save
            </LoadingButton>
          ) : (
            <Button onClick={handleSave} variant="contained" color="secondary" sx={{ width: '130px', textTransform: 'none' }}>
              Save
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default AddPageModal