import { useEffect } from 'react'
import { Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, Select, MenuItem, Tooltip } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ElementLayer from './ElementLayer'
import { sortElements } from '../../../utils/sortElements'

const elementRoleOptions = [
  "Not Relevant",
  "Login input",
  "Password input",
  "Sign-in button",
  "Login popup button"
];

const LoginPageBlocksAccordion = ({
  elementsIdRoles,
  setElementsIdRoles,
  sections,
  setSections,
  selectedSection,
  setSelectedSection,
  selectedElement,
  setSelectedElement,
  handleElementModalOpen
}) => {
  useEffect(() => {
    setSelectedElement(null)
    if (selectedSection.elements?.length) {
      setSelectedElement(selectedSection.elements[0])
    }
  // eslint-disable-next-line
  }, [selectedSection])

  const handleSectionClick = (block) => {
    setSelectedSection(block)
    if (block.elements.length) {
      setSelectedElement(sortElements(block.elements)[0])
    } else {
      setSelectedElement(null)
    }
  }

  const handleElementClick = (element) => setSelectedElement(element)

  const handleRoleChange = (element, newRole) => {
    const updatedElementsIdRoles = elementsIdRoles.map((el) => {
      if (el.role === newRole && el.id !== element.id) {
        return { ...el, role: "Not Relevant" }
      }
      if (el.id === element.id) {
        return { ...el, role: newRole }
      }
      return el
    })

    setElementsIdRoles(updatedElementsIdRoles)

    const updatedSelectedSection = {
      ...selectedSection,
      elements: selectedSection.elements.map((prevElement) => {
        if (prevElement.role === newRole && prevElement.id !== element.id) {
          return { ...prevElement, role: "Not Relevant" }
        }
        if (prevElement.id === element.id) {
          return { ...prevElement, role: newRole }
        }
        return prevElement
      }),
    }

    setSelectedSection(updatedSelectedSection)

    const updatedSections = sections.map((section) =>
      section.id === updatedSelectedSection.id ? updatedSelectedSection : section
    )

    setSections(updatedSections)
  }

  const prepareElementLabel = (xpath) => {
    const xPathItems = xpath.split("/")
    const elementLabel = xPathItems[xPathItems.length - 1].replace(/\[\d+\]/, "")
    return elementLabel
  }

  return (
    <Box className="loginPageBlocksAccordionContainer">
      <Accordion defaultExpanded sx={{ borderRadius: '8px !important', boxShadow: 'none' }}>
        <AccordionSummary className="accordionSummary" expandIcon={<ExpandMoreIcon />}>
          <Typography>Interactive elements</Typography>
        </AccordionSummary>
        <AccordionDetails className="accordionDetails">
          <Box sx={{ width: '15%', height: '50vh' }}>
            <Card className="accordionDetailsSectionsContainer">
              <CardContent sx={{ minWidth: '100%', p: '4px 8px 8px 8px !important' }}>
                <Typography variant="h6" sx={{ height: '32px' }}>Sections</Typography>
                <Box className="accordionDetailsSectionsContent">
                  {sections.map((section, index) => (
                    <Button
                      key={section.id}
                      className="accordionDetailsSectionsContentButton"
                      onClick={() => handleSectionClick(section)}
                      sx={{ backgroundColor: selectedSection?.id === section.id ? 'primary.medium' : 'transparent' }}
                    >
                      {`Scroll ${index+1}`}
                      {section.elements?.length ? <span className="accordionDetailsSectionsContentButtonElements">{section.elements.length}</span> : null}
                    </Button>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: '55%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card onClick={handleElementModalOpen} sx={{ boxShadow: 'none', borderRadius: '0 0 8px 8px', cursor: 'pointer' }}>
              <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: '8px !important' }}>
                <Box sx={{ position: 'relative', lineHeight: 0 }}>
                  <img
                    className="accordionDetailsImage"
                    src={selectedSection?.img_storage_link}
                    alt="Block Screenshot"
                  />
                  {selectedElement &&
                    <ElementLayer
                      top={selectedElement.b_box_relative_top}
                      right={selectedElement.b_box_relative_right}
                      bottom={selectedElement.b_box_relative_bottom}
                      left={selectedElement.b_box_relative_left}
                    />
                  }
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Box sx={{ width: '30%', height: '50vh' }}>
            <Card className="accordionDetailsElementsContainer">
              <CardContent sx={{ minWidth: '100%', p: '4px 8px 8px 8px !important' }}>
                <Typography variant="h6" sx={{ height: '32px' }}>Elements</Typography>
                <Box className="accordionDetailsElementsContent">
                  { selectedSection.elements?.length ?
                    <>
                      { selectedSection.elements.map(
                        (element) => {

                          return element.text ?
                            <Tooltip
                              key={element.id}
                              title={element.text}
                              PopperProps={{
                                sx: {
                                  '& .MuiTooltip-tooltip': {
                                    backgroundColor: 'primary.main',
                                  }
                                },
                              }}
                            >
                              <Button
                                className="accordionDetailsElementsContentButton"
                                onClick={() => handleElementClick(element)}
                                sx={{ backgroundColor: selectedElement === element ? 'primary.medium' : 'transparent' }}
                              >
                                <span style={{ textAlign: 'left', display: 'inline-block', minWidth: '94px' }}>{`${prepareElementLabel(element.xpath ? element.xpath : element.xPath)}`}</span>
                                <Select
                                  size='small'
                                  value={element.role || "Not Relevant"}
                                  onChange={(e) => handleRoleChange(element, e.target.value)}
                                  sx={{ ml: 0.5, minWidth: 174 }}
                                >
                                  {elementRoleOptions.map((role) => (
                                    <MenuItem key={role} value={role}>
                                      {role}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Button>
                            </Tooltip> :
                            <Button
                              key={element.id}
                              className="accordionDetailsElementsContentButton"
                              onClick={() => handleElementClick(element)}
                              sx={{ backgroundColor: selectedElement === element ? 'primary.medium' : 'transparent' }}
                            >
                              <span style={{ textAlign: 'left', display: 'inline-block', minWidth: '94px' }}>{`${prepareElementLabel(element.xpath ? element.xpath : element.xPath)}`}</span>
                              <Select
                                size='small'
                                value={element.role || "Not Relevant"}
                                onChange={(e) => handleRoleChange(element, e.target.value)}
                                sx={{ ml: 0.5, minWidth: 174 }}
                              >
                                {elementRoleOptions.map((role) => (
                                  <MenuItem key={role} value={role}>
                                    {role}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Button>
                        }
                      )
                      }
                    </> :
                    <Typography className="accordionDetailsElementsContentText">
                      Login failed, probably to an &quot;anti-bot&quot; protection, if you are an owner of the website you can temporarily turn this protection off and then click the &quot;Re-setup login&quot; button in the project&apos;s login settings.
                    </Typography>
                  }
                </Box>
              </CardContent>
            </Card>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default LoginPageBlocksAccordion