import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button, Typography, Pagination } from '@mui/material'
import { useProject } from '../context/ProjectContext'
import { socketEventEmitter } from '../socket'
import TaskService from '../services/TaskService'
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import TableHeader from '../components/taskManagement/TableHeader'
import TaskListItem from '../components/taskManagement/TaskListItem'
import DeleteTasksConfirmationModal from '../components/modals/DeleteTasksConfirmationModal'
import { sortTableItems } from '../utils/sortTableItems'

const TaskManagement = () => {
  const { selectedProject } = useProject()
  const [tasksList, setTasksList] = useState([])
  const [tasksTotal, setTasksTotal] = useState(0)
  const [checkedTasks, setCheckedTasks] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [tasksToggler, setTasksToggler] = useState(false)
  const [tasksToDelete, setTasksToDelete] = useState([])
  const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false)
  const [sortConfig, setSortConfig] = useState({ key: 'started_at', direction: 'desc' })
  const [page, setPage] = useState(1)
  const openAnchorEl = Boolean(anchorEl)
  const tasksPerPage = 10
  const startItem = (page - 1) * tasksPerPage + 1
  const endItem = Math.min(page * tasksPerPage, tasksTotal)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await TaskService.getAllTasksByProjectId(selectedProject?.id)
        setTasksList(res.tasks)
        setTasksTotal(res.total)
      } catch (error) {
        console.error('Error fetching tasks:', error)
      }
    }

    fetchData()

    const handleTaskCompleted = async () => {
      fetchData()
    }

    const handleTaskActivated = async () => {
      fetchData()
    }

    const handleTaskFailed = async () => {
      fetchData()
    }

    socketEventEmitter.on('taskCompleted', handleTaskCompleted)
    socketEventEmitter.on('taskActivated', handleTaskActivated)
    socketEventEmitter.on('taskFailed', handleTaskFailed)

    return () => {
      socketEventEmitter.off('taskCompleted', handleTaskCompleted)
      socketEventEmitter.off('taskActivated', handleTaskActivated)
      socketEventEmitter.off('taskFailed', handleTaskFailed)
    }
  // eslint-disable-next-line
  }, [tasksToggler, selectedProject?.id])

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const handleOpenDeleteDialog = (taskId) => {
    if (taskId) {
      setTasksToDelete([taskId])
    } else {
      setTasksToDelete(checkedTasks)
    }
    setDeleteOpen(true)
  }

  const handleClickCheckAllDropdown = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseCheckAllDropdown = () => {
    setAnchorEl(null)
  }

  const handleCheckAllCurrentPage = () => {
    const currentPageTaskIds = paginatedTasks.map(task => task.id)
    setCheckedTasks(prevCheckedTasks => [
      ...new Set([...prevCheckedTasks, ...currentPageTaskIds])
    ])
    setIsHeaderCheckboxChecked(true)
    handleCloseCheckAllDropdown()
  }

  const handleUncheckAllCurrentPage = () => {
    const currentPageTaskIds = paginatedTasks.map(task => task.id)
    setCheckedTasks(prevCheckedTasks =>
      prevCheckedTasks.filter(taskId => !currentPageTaskIds.includes(taskId))
    )
    setIsHeaderCheckboxChecked(false)
    handleCloseCheckAllDropdown()
  }

  const handleCheckboxChange = (id) => {
    setCheckedTasks(prevCheckedTasks => {
      if (prevCheckedTasks.includes(id)) {
        return prevCheckedTasks.filter(taskId => taskId !== id)
      } else {
        return [...prevCheckedTasks, id]
      }
    })
  }

  const handleSortChange = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }))
  }

  const sortedTasks = sortTableItems(tasksList, sortConfig)

  const paginatedTasks = sortedTasks.slice((page - 1) * tasksPerPage, page * tasksPerPage)

  return (
    <Box>
      <Helmet>
        <title>{`Treegress - ${selectedProject.name} Tasks`}</title>
      </Helmet>
      <Header title="Tasks"/>
      <Box sx={{ display: 'flex', pt: '56px' }}>
        <Sidebar />
        <Box className='tasks'>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, minHeight: '36.5px' }}>
            <Typography variant="h6">Task Management</Typography>
            <Box>
              {checkedTasks.length ?
                <Button variant="outlined" color="error" onClick={() => handleOpenDeleteDialog()} sx={{  width: '200px', mr: 2, textTransform: 'none' }}>
                  Delete Tasks
                </Button> : null
              }
            </Box>
          </Box>
          <TableContainer className='tasksTableContainer' component={Paper} sx={{ boxShadow: 'none' }}>
            <Table className='tasksTable' stickyHeader>
              <TableHeader
                isHeaderCheckboxChecked={isHeaderCheckboxChecked}
                handleCheckAllCurrentPage={handleCheckAllCurrentPage}
                handleUncheckAllCurrentPage={handleUncheckAllCurrentPage}
                handleClickCheckAllDropdown={handleClickCheckAllDropdown}
                handleCloseCheckAllDropdown={handleCloseCheckAllDropdown}
                anchorEl={anchorEl}
                openAnchorEl={openAnchorEl}
                handleSortChange={handleSortChange}
                sortConfig={sortConfig}
              />
              <TableBody>
                { paginatedTasks.length ?
                  paginatedTasks.map((task) => (
                    <TaskListItem
                      key={task.id}
                      task={task}
                      checkedTasks={checkedTasks}
                      tasksToggler={tasksToggler}
                      setTasksToggler={setTasksToggler}
                      handleCheckboxChange={handleCheckboxChange}
                      handleOpenDeleteDialog={handleOpenDeleteDialog}
                    />
                  )) :
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Typography variant="body1" align="center">
                        No data available
                      </Typography>
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          { tasksList.length ?
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
              <Typography variant="body2" mr={1}>
                {`${startItem}-${endItem} of ${tasksTotal}`}
              </Typography>
              <Pagination
                count={Math.ceil(tasksTotal / tasksPerPage)}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                color='primary'
              />
            </Box> : null }
        </Box>
      </Box>
      <DeleteTasksConfirmationModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        tasksToggler={tasksToggler}
        setTasksToggler={setTasksToggler}
        tasksToDelete={tasksToDelete}
        setCheckedTasks={setCheckedTasks}
      />
    </Box>
  )
}

export default TaskManagement