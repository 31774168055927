import { createContext, useState, useEffect, useContext } from 'react'
import { useAuth } from './AuthContext'

const ProjectContext = createContext()

export const ProjectProvider = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const [selectedProject, setSelectedProject] = useState(() => {
    const savedProject = localStorage.getItem('selectedProject')
    return savedProject ? JSON.parse(savedProject) : undefined
  })

  useEffect(() => {
    if (selectedProject) {
      localStorage.setItem('selectedProject', JSON.stringify(selectedProject))
    } else {
      localStorage.removeItem('selectedProject')
    }
  }, [selectedProject])

  useEffect(() => {
    if (!isAuthenticated) {
      setSelectedProject(undefined)
    }
  }, [isAuthenticated])

  return (
    <ProjectContext.Provider value={{ selectedProject, setSelectedProject }}>
      {children}
    </ProjectContext.Provider>
  )
}

export const useProject = () => {
  return useContext(ProjectContext)
}