import { Box, Button } from '@mui/material'

const TestRunsActions = ({ checkedTestRuns, handleDeleteTestRuns }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {checkedTestRuns.length ?
        <Button
          variant="outlined"
          color="error"
          onClick={handleDeleteTestRuns}
          sx={{ width: '200px', textTransform: 'none', mr: 2 }}
        >
          Delete Test Runs
        </Button> : null
      }
    </Box>
  )
}

export default TestRunsActions