import { createContext, useContext } from 'react'
import { SnackbarProvider, useSnackbar } from 'notistack'
import { Box, Alert, Link } from '@mui/material'

const NotificationContext = createContext()

export const useNotification = () => useContext(NotificationContext)

export const NotificationProvider = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const showNotification = (message, severity = 'info', linkText = 'Learn more', navigate, linkTo = '/tasks', navigatonState=null, description='') => {
    enqueueSnackbar(message, {
      content: (key) => (
        <Alert
          severity={severity}
          variant="outlined"
          sx={{ width: '610px', backgroundColor: '#fff' }}
        >
          <Box sx={{ width: '540px', display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ display: 'inline-block' }}>{message}</span>
              {description ? <span style={{ display: 'inline-block' }}>{description}</span> : null}
            </Box>
            {linkText && (
              <Link
                sx={{ marginLeft: 1, minWidth: '90px', cursor: 'pointer' }}
                onClick={() => {
                  closeSnackbar(key)
                  navigate(linkTo, navigatonState)
                }}
              >
                {linkText}
              </Link>
            )}
          </Box>
        </Alert>
      ),
      anchorOrigin: { vertical: 'top', horizontal: 'center' },
      autoHideDuration: 5000
    })
  }

  return (
    <NotificationContext.Provider value={showNotification}>
      {children}
    </NotificationContext.Provider>
  )
}

export const AppWithNotifications = ({ children }) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <NotificationProvider>{children}</NotificationProvider>
    </SnackbarProvider>
  )
}
