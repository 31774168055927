import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { TableBody, TableRow, TableCell, Checkbox, Typography, IconButton, Tooltip } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { formatDate } from '../../../../utils/formatDate'

const TableContent = ({ pagesList, prevPagesList, paginatedPages, checkedPages, selectedScanStatus, handleCheckboxChange, handleOpenDeleteDialog }) => {
  const [newPages, setNewPages] = useState([])
  const [isProjectChanged, setIsProjectChanged] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    setIsProjectChanged(!prevPagesList.every(prevPage =>
      pagesList.some(page => page.id === prevPage.id)
    ))

    if (pagesList.length && prevPagesList.length && pagesList[0].project_id === prevPagesList[0].project_id) {
      const newPageIds = pagesList
        .filter(page => !prevPagesList.some(prevPage => prevPage.id === page.id))
        .map(page => page.id)
      setNewPages(newPageIds)
    }
  }, [pagesList, prevPagesList])

  const handleRowClick = (pageId) => {
    navigate(`/pages/${pageId}`)
  }

  const handleCopyToClipboard = (pageUrl, pageId) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(pageUrl)
    } else {
      const textArea = document.createElement("textarea")
      textArea.value = pageUrl
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand("copy")
      document.body.removeChild(textArea)
    }

    setTooltipOpen(pageId)
    setTimeout(() => {
      setTooltipOpen('')
    }, 1000)
  }

  const handleTooltipClose = () => {
    setTooltipOpen(false)
  }

  return ( paginatedPages.length ?
    <TableBody>
      {paginatedPages.map((page) => {
        let isNew
        if (isProjectChanged) {
          isNew = false
        } else {
          isNew = newPages.includes(page.id)
        }

        const isChecked = checkedPages.includes(page.id)

        return (
          <TableRow
            key={page.id}
            hover
            onClick={() => handleRowClick(page.id)}
            sx={{
              backgroundColor: isNew || isChecked ? 'rgba(69, 116, 210, 0.1)' : 'inherit',
              cursor: 'pointer'
            }}
          >
            <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
              <Checkbox
                checked={checkedPages.includes(page.id)}
                onChange={() => handleCheckboxChange(page.id)}
              />
            </TableCell>
            <TableCell sx={{ maxWidth: '500px', minWidth: '160px', wordBreak: 'break-word', fontWeight: isNew ? 500 : 400 }}>{page.label || 'N/A'}</TableCell>
            <TableCell sx={{ position: 'relative', maxWidth: '500px', minWidth: '200px', pr: 3, wordBreak: 'break-word', fontWeight: isNew ? 500 : 400 }}>
              {page.url}
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'primary.main'
                    },
                  },
                }}
                onClose={handleTooltipClose}
                open={tooltipOpen === page.id}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied to clipboard!"
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    handleCopyToClipboard(page.url, page.id)
                  }}
                  sx={{ position: 'absolute', right: -8, top: '50%', transform: 'translateY(-50%)' }}
                >
                  <ContentCopyIcon color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell align='center' sx={{ fontWeight: isNew ? 500 : 400 }}>{page.created_at ? formatDate(page.created_at) : 'N/A'}</TableCell>
            <TableCell align='center' sx={{ fontWeight: isNew ? 500 : 400 }}>{page.last_scanned_at ? formatDate(page.last_scanned_at) : 'Unscanned'}</TableCell>
            {page.passed_ui_test_count ?
              <TableCell align='center'>
                <Typography color="success.main" sx={{ fontWeight: 500, ml: 2 }}>{page.passed_ui_test_count}</Typography>
              </TableCell> :
              <TableCell align='center' sx={{ fontWeight: isNew ? 500 : 400 }}>
                <Typography ml={2}>N/A</Typography>
              </TableCell>
            }
            {page.last_scanned_at ?
              <TableCell align='center'>
                <Typography color="error.main" sx={{ fontWeight: 500, ml: 2 }}>{page.failed_ui_test_count}</Typography>
              </TableCell> :
              <TableCell align='center' sx={{ fontWeight: isNew ? 500 : 400 }}>
                <Typography ml={2}>N/A</Typography>
              </TableCell>
            }
            <TableCell align='center'>
              {page.include_into_scanning ? <DoneIcon color="success" /> : <CloseIcon color="error" />}
            </TableCell>
            <TableCell align='center' sx={{ pl: 0, pr: 1 }}>
              <IconButton onClick={(e) => {e.stopPropagation(); handleOpenDeleteDialog(page?.id)}}>
                <DeleteOutlineOutlinedIcon color='primary' />
              </IconButton>
            </TableCell>
          </TableRow>
        )}
      )}
    </TableBody> :
    pagesList.length && selectedScanStatus === "Scanned" && !paginatedPages.length ?
      <TableBody>
        <TableRow>
          <TableCell colSpan={9}>
            <Typography variant="body1" align="center">
              No data available yet! Please select the page(s) you want to scan and click the corresponding button to get started.
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody> :
      pagesList.length && selectedScanStatus === "Unscanned" && !paginatedPages.length ?
        <TableBody>
          <TableRow>
            <TableCell colSpan={9}>
              <Typography variant="body1" align="center">
                <strong>All pages scanned!</strong><br/>
                It looks like you’ve scanned all the discovered pages. You can manually add new pages by clicking the corresponding button.
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody> :
        <TableBody>
          <TableRow>
            <TableCell colSpan={9}>
              <Typography variant="body1" align="center">
                Website Mapping is nearly ready, so you’ll soon be able to view and select pages for scanning.
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
  )
}

export default TableContent