export const sortTableItems = (itemsArray, sortConfig) => {
  return itemsArray.sort((a, b) => {
    const valueA = a[sortConfig.key]
    const valueB = b[sortConfig.key]

    if (valueA === null || valueB === null) {
      if (valueA === null && valueB === null) return 0
      if (valueA === null) return sortConfig.direction === 'asc' ? -1 : 1
      if (valueB === null) return sortConfig.direction === 'asc' ? 1 : -1
    }

    if (typeof valueA === 'boolean' && typeof valueB === 'boolean') {
      return sortConfig.direction === 'asc'
        ? (valueA === valueB ? 0 : (valueA ? -1 : 1))
        : (valueA === valueB ? 0 : (valueA ? 1 : -1))
    }

    if (
      sortConfig.key === 'created_at' ||
      sortConfig.key === 'last_scanned_at' ||
      sortConfig.key === 'started_at' ||
      sortConfig.key === 'updated_at' ||
      sortConfig.key === 'queued_at'
    ) {
      const dateA = new Date(valueA)
      const dateB = new Date(valueB)
      return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
    }

    if (sortConfig.key === 'status') {
      const statusOrder = {
        'Not Started': 1,
        'pending': 1,
        'In Progress': 2,
        'in_progress': 2,
        'Scanned': 3,
        'Passed': 3,
        'completed': 3,
        'Failed': 4,
        'failed': 4
      };
      const statusA = statusOrder[valueA] || 0;
      const statusB = statusOrder[valueB] || 0;
      return sortConfig.direction === 'asc' ? statusA - statusB : statusB - statusA;
    }

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return sortConfig.direction === 'asc'
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA)
    }

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return sortConfig.direction === 'asc'
        ? valueA - valueB
        : valueB - valueA
    }

    return 0
  })
}