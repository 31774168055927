import { Box, Button } from '@mui/material'

const TestRunActions = ({ handleRunTestCase }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleRunTestCase}
        sx={{ width: '160px', textTransform: 'none', mr: 2 }}
      >
        Run
      </Button>
    </Box>
  )
}

export default TestRunActions