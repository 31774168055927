import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { HelmetProvider } from 'react-helmet-async'
import theme from './theme'
import './styles/index.scss'
import Hotjar from './utils/Hotjar'
import IntercomMessenger from './utils/IntercomMessenger'
import Layout from './components/Layout'
import Projects from './pages/Projects'
import Dashboard from './pages/Dashboard'
import Pages from './pages/Pages'
import Discovery from './pages/Discovery'
import TestCase from './pages/TestCase'
import TestRun from './pages/TestRun'
import Profile from './pages/Profile'
import Tutorial from './pages/Tutorial'
import TaskManagement from './pages/TaskManagement'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import EmailConfirmation from './pages/EmailConfirmation'
import EmailVerification from './pages/EmailVerification'
import SharedReport from './pages/SharedReport'
import ProjectSettings from './pages/ProjectSettings'
import Invitation from './pages/Invitation'
import { AuthProvider } from './context/AuthContext'
import { AppWithNotifications } from './context/NotificationContext'
import { AllProjectsProvider } from './context/AllProjectsContext'
import { ProjectProvider } from './context/ProjectContext'
import { SidebarProvider } from './context/SidebarContext'
import ProtectedRoute from './components/ProtectedRoute'
import addExternalScripts from './utils/addExternalScripts'

addExternalScripts()

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      { index: true, element: <Projects /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "pages", element: <Pages /> },
      { path: "pages/:id", element: <Discovery /> },
      { path: "test-case/:id", element: <TestCase /> },
      { path: "test-run/:id", element: <TestRun /> },
      { path: "project/:id", element: <ProjectSettings /> },
      { path: "profile", element: <Profile /> },
      { path: "tutorial", element: <Tutorial /> },
      { path: "tasks", element: <TaskManagement /> },
      { path: "report/:id", element: <SharedReport /> }
    ]
  },
  { path: "login", element: <Login /> },
  { path: "signup", element: <SignUp /> },
  { path: "invitation", element: <Invitation /> },
  { path: "confirmation", element: <EmailConfirmation /> },
  { path: "verify-email/:token", element: <EmailVerification /> },
]);

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <AuthProvider>
        <AppWithNotifications>
          <AllProjectsProvider>
            <ProjectProvider>
              <SidebarProvider>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <Hotjar />
                  <IntercomMessenger />
                  <RouterProvider router={router} />
                </ThemeProvider>
              </SidebarProvider>
            </ProjectProvider>
          </AllProjectsProvider>
        </AppWithNotifications>
      </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
)