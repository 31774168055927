import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Pagination, Checkbox, Menu, MenuItem, IconButton, Tooltip } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PageService from '../../../../services/PageService'
import { socketEventEmitter } from '../../../../socket'
import { useProject } from '../../../../context/ProjectContext'
import StatsSummary from '../../../StatsSummary'
import TestCasesActions from './TestCasesActions'
import SortingIcon from '../../../SortingIcon'
import TestCasesListItem from './TestCasesListItem'
import DeleteTestCasesConfirmationModal from './DeleteTestCasesConfirmationModal'
import { sortTableItems } from '../../../../utils/sortTableItems'

const TestCasesTab = ({ pageData }) => {
  const navigate = useNavigate()
  const { selectedProject } = useProject()

  const [allTestCases, setAllTestCases] = useState([])
  const [draftTestCases, setDraftTestCases] = useState([])
  const [testCasesTotal, setTestCasesTotal] = useState(0)
  const [checkedTestCases, setCheckedTestCases] = useState([])
  const [testCasesToDelete, setTestCasesToDelete] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false)
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' })
  const [isGeneratingTestCasesLoading, setIsGeneratingTestCasesLoading] = useState(false)
  const [tabToggler, setTabToggler] = useState(false)
  const [page, setPage] = useState(1)
  const [deleteOpen, setDeleteOpen] = useState(false)

  const openAnchorEl = Boolean(anchorEl)
  const testCasesPerPage = 10
  const startItem = (page - 1) * testCasesPerPage + 1
  const endItem = Math.min(page * testCasesPerPage, testCasesTotal)

  useEffect(() => {
    if (pageData && selectedProject?.id && pageData.page.project_id !== selectedProject.id) {
      navigate(`/pages`)
    }
    const getAllTestCases = async () => {
      try {
        const testCasesData = await PageService.getAllTestCasesByPageId(pageData.page.id)
        // console.log('testCases: ', testCasesData.testCases)
        setAllTestCases(testCasesData.testCases)
        setTestCasesTotal(testCasesData.total)
        setDraftTestCases(testCasesData.testCases.filter(testCase => testCase.status === 'Draft').map(testCase => testCase.id))
      } catch (error) {
        console.error('Error fetching test cases:', error)
      }
    }

    if (pageData?.page?.id) {
      getAllTestCases()
    }

    const handleTaskCompleted = async (data) => {
      // console.log('(Test Cases Tab) Task completed:', data)
      if (data.pageId === pageData?.page?.id && (data.type === 'Test Case' || data.type === 'Discover Test Cases')) {
        setTabToggler(!tabToggler)
      }
      setIsGeneratingTestCasesLoading(false)
    }

    const handleTaskFailed = async (data) => {
      // console.log('(Test Cases Tab) Task failed:', data)
      if (data.pageId === pageData?.page?.id && (data.type === 'Test Case' || data.type === 'Discover Test Cases')) {
        setTabToggler(!tabToggler)
      }
      setIsGeneratingTestCasesLoading(false)
    }

    socketEventEmitter.on('taskCompleted', handleTaskCompleted)
    socketEventEmitter.on('taskFailed', handleTaskFailed)

    return () => {
      socketEventEmitter.off('taskCompleted', handleTaskCompleted)
      socketEventEmitter.off('taskFailed', handleTaskFailed)
    }
  }, [selectedProject?.id, pageData, tabToggler, navigate])

  const handleApproveTestCases = async () => {
    if (checkedTestCases.length) {
      try {
        await PageService.runTestCases(checkedTestCases)
      } catch (error) {
        console.log(error)
      }
    } else {
      try {
        await PageService.runTestCases(draftTestCases)
      } catch (error) {
        console.log(error)
      }
    }
    setTabToggler(!tabToggler)
  }

  const handleRunTestCases = async () => {
    try {
      await PageService.runTestCases(checkedTestCases)
    } catch (error) {
      console.log(error)
    }
    setTabToggler(!tabToggler)
  }

  const handleRunAllTestCases = async () => {
    try {
      await PageService.runTestCases(allTestCases.map(testCase => testCase.id))
    } catch (error) {
      console.log(error)
    }
    setTabToggler(!tabToggler)
  }

  const handleGenerateTestCases = async () => {
    setIsGeneratingTestCasesLoading(true)
    try {
      await PageService.discoverTestCasesByPageId(pageData.page.id)
    } catch (error) {
      console.log(error)
      setIsGeneratingTestCasesLoading(false)
    }
  }

  const handleOpenLink = () => {
    window.open(pageData?.page?.url, '_blank', 'noopener,noreferrer')
  }

  const handleOpenDeleteDialog = (testCaseID) => {
    if (testCaseID) {
      setTestCasesToDelete([testCaseID])
    } else {
      setTestCasesToDelete(checkedTestCases)
    }
    setDeleteOpen(true)
  }

  const handleClickCheckAllDropdown = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseCheckAllDropdown = () => {
    setAnchorEl(null)
  }

  const handleCheckAllCurrentPage = () => {
    const currentPageTestCaseIds = paginatedTestCases.map(testCase => testCase.id)
    setCheckedTestCases(prevCheckedTestCases => [
      ...new Set([...prevCheckedTestCases, ...currentPageTestCaseIds])
    ])
    setIsHeaderCheckboxChecked(true)
    handleCloseCheckAllDropdown()
  }

  const handleUncheckAllCurrentPage = () => {
    const currentPageTestCaseIds = paginatedTestCases.map(testCase => testCase.id)
    setCheckedTestCases(prevCheckedTestCases =>
      prevCheckedTestCases.filter(testCaseId => !currentPageTestCaseIds.includes(testCaseId))
    )
    setIsHeaderCheckboxChecked(false)
    handleCloseCheckAllDropdown()
  }

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const handleCheckboxChange = (id) => {
    setCheckedTestCases(prevCheckedTestCases => {
      if (prevCheckedTestCases.includes(id)) {
        return prevCheckedTestCases.filter(testCaseId => testCaseId !== id)
      } else {
        return [...prevCheckedTestCases, id]
      }
    })
  }

  const handleSortChange = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }))
  }

  const sortedTestCases = sortTableItems(allTestCases, sortConfig)

  const paginatedTestCases = sortedTestCases.slice((page - 1) * testCasesPerPage, page * testCasesPerPage)

  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <StatsSummary
          title="Total test cases"
          value={ allTestCases.length ?
            allTestCases.length :
            'N/A'
          }
          color="primary"
        />
      </Box>
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6" color="primary" sx={{ m: '8px 0' }}>{pageData?.page?.label}</Typography>
        <TestCasesActions
          draftTestCases={draftTestCases}
          checkedTestCases={checkedTestCases}
          handleApproveTestCases={handleApproveTestCases}
          handleRunTestCases={handleRunTestCases}
          handleRunAllTestCases={handleRunAllTestCases}
          isGeneratingTestCasesLoading={isGeneratingTestCasesLoading}
          handleGenerateTestCases={handleGenerateTestCases}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Typography variant="body1" color="primary">{pageData?.page?.url}</Typography>
        <Tooltip
          title="Open in a new tab"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              },
            },
          }}
        >
          <IconButton sx={{ ml: 0.5 }} onClick={handleOpenLink}>
            <OpenInNewIcon color='primary'/>
          </IconButton>
        </Tooltip>
      </Box>
      <TableContainer className='testCasesTableContainer' component={Paper} sx={{ boxShadow: 'none' }}>
        <Table className='testCasesTable' stickyHeader>
          <TableHead sx={{ borderBottom: '2px solid #D7E2EC' }}>
            <TableRow>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox
                    sx={{ ml: '17px', p: 0 }}
                    checked={isHeaderCheckboxChecked}
                    onChange={(e) => {
                      const allChecked = e.target.checked
                      if (allChecked) {
                        handleCheckAllCurrentPage()
                      } else {
                        handleUncheckAllCurrentPage()
                      }
                    }}
                  />
                  <IconButton
                    onClick={handleClickCheckAllDropdown}
                    size="small"
                    sx={{ p: 0 }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={openAnchorEl}
                    onClose={handleCloseCheckAllDropdown}
                  >
                    <MenuItem onClick={handleCheckAllCurrentPage} sx={{ minWidth: '100px' }}>All</MenuItem>
                    <MenuItem onClick={handleUncheckAllCurrentPage}>None</MenuItem>
                  </Menu>
                </Box>
              </TableCell>
              <TableCell sx={{ minWidth: '200px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('name')}>
                <Box className="tableCellContentWithBorder">
                  <SortingIcon sortConfig={sortConfig} currentKey={'name'}/>
                  Test Case Name
                </Box>
              </TableCell>
              <TableCell sx={{ minWidth: '202px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('status')}>
                <Box className="tableCellContentWithBorder">
                  <SortingIcon sortConfig={sortConfig} currentKey={'status'}/>
                  Execution Status
                </Box>
              </TableCell>
              <TableCell sx={{ minWidth: '160px', textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('created_at')}>
                <Box className="tableCellContentWithBorder">
                  <SortingIcon sortConfig={sortConfig} currentKey={'created_at'}/>
                  Creation Date
                </Box>
              </TableCell>
              <TableCell sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleSortChange('updated_at')}>
                <Box className="tableCellContentWithBorder">
                  <SortingIcon sortConfig={sortConfig} currentKey={'updated_at'}/>
                  Last Run Date
                </Box>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { paginatedTestCases.length ?
              paginatedTestCases.map((testCase) => (
                <TestCasesListItem
                  key={testCase.id}
                  testCase={testCase}
                  checkedTestCases={checkedTestCases}
                  handleCheckboxChange={handleCheckboxChange}
                  handleOpenDeleteDialog={handleOpenDeleteDialog}
                />
              )) :
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography variant="body1" sx={{ minHeight: '42px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    No data available. Click on &apos;Generate Test Cases&apos; button.
                  </Typography>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      { allTestCases.length ?
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', mt: 2 }}>
          <Typography variant="body2" mr={1}>
            {`${startItem}-${endItem} of ${testCasesTotal}`}
          </Typography>
          <Pagination
            count={Math.ceil(testCasesTotal / testCasesPerPage)}
            page={page}
            onChange={handlePageChange}
            shape="rounded"
            color='primary'
          />
        </Box> : null }
      <DeleteTestCasesConfirmationModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        tabToggler={tabToggler}
        setTabToggler={setTabToggler}
        testCasesToDelete={testCasesToDelete}
        setCheckedTestCases={setCheckedTestCases}
      />
    </Box>
  )
}

export default TestCasesTab