import { useNavigate } from 'react-router-dom'
import { TableCell, TableRow, Box, Checkbox, IconButton, Tooltip } from '@mui/material'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { calculateRunTime } from '../../../../utils/calculateRunTime'

const TestRunsListItem = ({ testRun, checkedTestRuns, handleCheckboxChange, handleOpenDeleteDialog }) => {
  const navigate = useNavigate()

  const statusStyles = {
    'PENDING': { backgroundColor: '#6D6E6F', color: '#fff' },
    'RUNNING': { backgroundColor: '#4574D2', color: '#fff' },
    'COMPLETED': { backgroundColor: '#198754', color: '#fff' },
    'FAILED': { backgroundColor: '#D32F2F', color: '#fff' },
    'CANCELLED': { backgroundColor: '#F8DF73', color: '#2D4356' }
  }

  const handleShowTestRun = () => {
    navigate(`/test-run/${testRun.id}`)
  }

  return (
    <TableRow key={testRun.id}>
      <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={checkedTestRuns.includes(testRun.id)}
          onChange={() => handleCheckboxChange(testRun.id)}
        />
      </TableCell>
      <TableCell>{testRun.id}</TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Box sx={{ ml: 2 }}>
          {new Date(testRun.queued_at).toLocaleString()}
        </Box>
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Box sx={{ ml: 2 }}>
          {testRun.started_at ? new Date(testRun.started_at).toLocaleString() : null}
        </Box>
      </TableCell>
      <TableCell sx={{ textAlign: 'center' }}>
        <Box sx={{ ml: 2 }}>
          { testRun.started_at && testRun.updated_at ? calculateRunTime(testRun.started_at, testRun.updated_at) : null}
        </Box>
      </TableCell>
      <TableCell>
        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
          <Box
            variant="contained"
            size="small"
            sx={{
              ...statusStyles[testRun.status],
              display: 'flex',
              justifyContent: 'center',
              width: '150px',
              minWidth: '120px',
              fontSize: '14px',
              borderRadius: '16px',
              p: '4px 16px',
              ml: 2,
              textTransform: 'capitalize'
            }}
          >
            {testRun.status}
          </Box>
        </Box>
      </TableCell>
      <TableCell sx={{ minWidth: '120px', textAlign: 'center' }}>
        <Tooltip
          title="Show Test Run"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              },
            },
          }}
        >
          <IconButton sx={{ mr: 1 }} onClick={(e) => {e.stopPropagation(); handleShowTestRun()}}>
            <VisibilityOutlinedIcon color='primary' />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="Delete Test Run"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              },
            },
          }}
        >
          <IconButton onClick={(e) => {e.stopPropagation(); handleOpenDeleteDialog(testRun?.id)}}>
            <DeleteOutlineOutlinedIcon color='primary' />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default TestRunsListItem