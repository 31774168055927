import { useNavigate } from 'react-router-dom'
import { TableCell, TableRow, Box, Checkbox, IconButton, Tooltip, CircularProgress, Typography } from '@mui/material'
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined'
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import TaskService from '../../services/TaskService'
import { useNotification } from '../../context/NotificationContext'
import { calculateRunTime } from '../../utils/calculateRunTime'

const TaskListItem = ({ task, checkedTasks, tasksToggler, setTasksToggler, handleCheckboxChange, handleOpenDeleteDialog }) => {
  const navigate = useNavigate()
  const showNotification = useNotification()

  const statusStyles = {
    'pending': { backgroundColor: '#6D6E6F', color: '#fff' },
    'in_progress': { backgroundColor: '#4574D2', color: '#fff' },
    'completed': { backgroundColor: '#198754', color: '#fff' },
    'failed': { backgroundColor: '#D32F2F', color: '#fff' },
    'stopped': { backgroundColor: '#F8DF73', color: '#2D4356' },
  }

  const handleRowClick = (task) => {
    if (task.task_type === 'Website Mapping' || task.task_type === 'Page Tree Scan') {
      navigate('/pages', { state: { view: 'hierarchical' } })
    } else if (task.task_type === 'UI Scan' || task.task_type === 'Test Case') {
      navigate(`/pages/${task.page_id}`)
    } else if (task.task_type === 'Login Test' || task.task_type === 'Login Elements Processing') {
      navigate(`/project/${task.project_id}`, { state: { activeTab: '2' } })
    } else {
      navigate('/')
    }
  }

  const handleStopTask = async () => {
    try {
      await TaskService.stopTaskById(task.id)
      showNotification('Task has been stopped successfully', 'success', '', navigate)
      setTasksToggler(!tasksToggler)
    } catch (error) {
      console.log(error.message)
      showNotification('Task could not be stopped', 'error', '', navigate)
    }
  }

  const handleRerunTask = async () => {
    try {
      await TaskService.rerunTaskById(task.id)
      showNotification('Task has been rerun successfully', 'info', '', navigate)
      setTasksToggler(!tasksToggler)
    } catch (error) {
      console.log(error.message)
      showNotification('Task could not be rerun', 'error', '', navigate)
    }
  }

  return (
    <TableRow
      key={task?.id}
      hover
      onClick={() => handleRowClick(task)}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell padding="checkbox" onClick={(e) => e.stopPropagation()}>
        <Checkbox
          checked={checkedTasks.includes(task?.id)}
          onChange={() => handleCheckboxChange(task?.id)}
        />
      </TableCell>
      <TableCell>{task.task_type}</TableCell>
      <TableCell>{task.page_name ? task.page_name : 'N/A'}</TableCell>
      <TableCell align='center'><Box ml={2}>{task.status !== "pending" ? new Date(task.started_at).toLocaleString() : 'N/A'}</Box></TableCell>
      <TableCell align='center'><Box ml={2}>{task.status !== "pending" ? calculateRunTime(task.started_at, task.updated_at) : null}</Box></TableCell>
      <TableCell align='center'>
        <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', ml: 2 }}>
          <Box
            variant="contained"
            size="small"
            sx={{
              ...statusStyles[task.status],
              display: 'flex',
              justifyContent: 'center',
              width: '140px',
              minWidth: '140px',
              fontSize: '14px',
              borderRadius: '16px',
              p: '4px 16px',
              textTransform: 'capitalize'
            }}
          >
            { task.status === "pending" || task.status === "in_progress" ? <CircularProgress color="white" size="18px" sx={{ mr: 1 }} /> : null }
            { task.status === "in_progress" ? "in progress" : task.status }
          </Box>
        </Box>
      </TableCell>
      <TableCell align='center'>
        { task?.Page?.screen_size && task.task_type === "UI Scan" ?
          <Typography sx={{ ml: 2, textTransform: 'capitalize' }}>{task.Page.screen_size}</Typography> : ''}
      </TableCell>
      <TableCell sx={{ wordBreak: 'break-word' }}>{task.error ? task?.error : ''}</TableCell>
      <TableCell sx={{ minWidth: '160px' }}>
        { task.status !== 'in_progress' || task.task_type === 'Login Test' ?
          <Box sx={{ display: 'inline-flex', verticalAlign: 'middle', mr: 0.5, p: 1, cursor: 'default', opacity: 0.3 }} onClick={(e) => e.stopPropagation()}>
            <StopCircleOutlinedIcon />
          </Box> :
          <Tooltip
            title="Stop"
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: 'primary.main'
                },
              },
            }}
          >
            <IconButton sx={{ mr: 0.5 }} onClick={(e) => {e.stopPropagation(); handleStopTask()}}>
              <StopCircleOutlinedIcon color='primary' />
            </IconButton>
          </Tooltip>
        }
        { task.status === 'in_progress' || task.status === 'pending' || task.task_type === 'Login Test' ?
          <Box sx={{ display: 'inline-flex', verticalAlign: 'middle', mr: 0.5, p: 1, cursor: 'default', opacity: 0.3 }} onClick={(e) => e.stopPropagation()}>
            <RestartAltOutlinedIcon />
          </Box> :
          <Tooltip
            title="Re-run"
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: 'primary.main'
                },
              },
            }}
          >
            <IconButton sx={{ mr: 0.5 }} onClick={(e) => {e.stopPropagation(); handleRerunTask()}}>
              <RestartAltOutlinedIcon color='primary' />
            </IconButton>
          </Tooltip>
        }
        <Tooltip
          title="Remove"
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                backgroundColor: 'primary.main'
              },
            },
          }}
        >
          <IconButton onClick={(e) => {e.stopPropagation(); handleOpenDeleteDialog(task?.id)}}>
            <DeleteOutlineOutlinedIcon color='primary' />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  )
}

export default TaskListItem