import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Paper, Table, TableContainer, Select, MenuItem, FormControl, InputLabel, Autocomplete, TextField, IconButton, Tooltip } from '@mui/material'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences'
// import { socketEventEmitter } from '../../socket'
import { useNotification } from '../../context/NotificationContext'
import PageService from '../../services/PageService'
import ProjectService from '../../services/ProjectService'
import ScanService from '../../services/ScanService'
import StatsSummary from '../StatsSummary'
import SearchBar from './SearchBar'
import ActionButtons from './ActionButtons'
import DeletePagesConfirmationModal from '../modals/DeletePagesConfirmationModal'
import TableHeader from './views/table/TableHeader'
import TableContent from './views/table/TableContent'
import PaginationComponent from './views/table/PaginationComponent'
import HierarchicalTableHeader from './views/hierarchical/HierarchicalTableHeader'
import HierarchicalTableContent from './views/hierarchical/HierarchicalTableContent'
import { sortTableItems } from '../../utils/sortTableItems'

const PagesContent = ({
  projectID,
  pagesList,
  prevPagesList,
  sitemap,
  prevSitemap,
  handleAddPageOpen,
  pagesToggler,
  setPagesToggler
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const showNotification = useNotification()

  const [view, setView] = useState('table')
  const [checkedPages, setCheckedPages] = useState([])
  const [selectedPage, setSelectedPage] = useState('')
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [pagesToDelete, setPagesToDelete] = useState([])
  const [page, setPage] = useState(1)
  const [projectStat, setProjectStat] = useState(null)
  const [filteredPages, setFilteredPages] = useState(pagesList)
  const [allIssueTypes, setAllIssueTypes] = useState([])
  const [selectedIssueTypes, setSelectedIssueTypes] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [isHeaderCheckboxChecked, setIsHeaderCheckboxChecked] = useState(false)
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' })
  const [selectedScanStatus, setSelectedScanStatus] = useState('All')
  const [loginRequired, setLoginRequired] = useState(false)

  const open = Boolean(anchorEl)
  const itemsPerPage = 10

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const includedIntoScanningTotal = pagesList.filter(page => page.include_into_scanning === true).length

  useEffect(() => {
    if (pagesList) {
      const newFilteredPages = pagesList.filter(page => {
        const matchesSearch = page.label?.toLowerCase()?.includes(selectedPage.toLowerCase()) || page.url.toLowerCase().includes(selectedPage.toLowerCase())

        const matchesScanStatus = selectedScanStatus === 'All' ||
          (selectedScanStatus === 'Scanned' && page.last_scanned_at) ||
          (selectedScanStatus === 'Unscanned' && !page.last_scanned_at)

        return matchesSearch && matchesScanStatus
      })

      setFilteredPages(newFilteredPages)
    }
  }, [selectedPage, selectedScanStatus, pagesList, projectID])

  useEffect(() => {
    if (projectID) {
      const getProjectData = async () => {
        try {
          const projectData = await ProjectService.getProjectById(projectID)
          setLoginRequired(projectData?.login_required)
          setProjectStat(projectData.stat)
        } catch (error) {
          console.error("Failed to fetch project data:", error)
        }
      }

      getProjectData()
    }
  }, [projectID])

  useEffect(() => {
    const getAllIssueTypesData = async () => {
      const allIssueTypesData = await ProjectService.getIssueTypes()
      setAllIssueTypes(allIssueTypesData)
      setSelectedIssueTypes(allIssueTypesData.map(type => type.description))
    }

    getAllIssueTypesData()
  }, [])

  useEffect(() => {
    if (location.state?.view) {
      setView(location.state.view)
    }
  }, [location.state])

  const sortedPages = sortTableItems(filteredPages, sortConfig)

  let paginatedPages
  const selectedIssueTypesIds = allIssueTypes.filter(type => selectedIssueTypes.includes(type.description)).map(type => type.id)
  const filteredPagesByIssues = sortedPages.filter(page =>
    page?.issue_classes?.some(issueClass => selectedIssueTypesIds.includes(issueClass))
  )

  if (allIssueTypes.length === selectedIssueTypes.length) {
    paginatedPages = sortedPages.slice(startIndex, endIndex)
  } else {
    paginatedPages = filteredPagesByIssues.slice(startIndex, endIndex)
  }

  const totalItems = allIssueTypes.length === selectedIssueTypes.length ? filteredPages.length : filteredPagesByIssues.length
  const totalPages = allIssueTypes.length === selectedIssueTypes.length ? Math.ceil(filteredPages.length / itemsPerPage) : Math.ceil(filteredPagesByIssues.length / itemsPerPage)

  const handleSortChange = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }))
  }

  const getAllChildIds = (page) => {
    let ids = [page.id]
    if (page.children) {
      page.children.forEach((child) => {
        ids = [...ids, ...getAllChildIds(child)]
      })
    }
    return ids
  }

  const handleCheckAll = (event) => {
    if (event.target.checked) {
      const allPageIds = sitemap.flatMap(page => getAllChildIds(page))
      setCheckedPages(allPageIds)
      setIsHeaderCheckboxChecked(true)
    } else {
      setCheckedPages([])
      setIsHeaderCheckboxChecked(false)
    }
  }

  const handleSearch = (event, newValue) => {
    setSelectedPage(newValue?.label || '')
    setPage(1)
  }

  const handleDiscoverMore = async () => {
    try {
      showNotification('Page Tree Scan has started', 'info', 'Learn more', navigate)
      await ProjectService.discoverMorePage(projectID, checkedPages)
      setCheckedPages([])
      setIsHeaderCheckboxChecked(false)
      setPagesToggler(!pagesToggler)
    } catch (error) {
      console.error('Error discovering more', error)
    }
  }

  const handleUpadateIncludingStatus = async (isIncluding) => {
    const pagesData = []
    pagesList
      .filter(page => checkedPages.includes(page.id))
      .forEach(page => page.page_ids.forEach(id => pagesData.push({ id, included: isIncluding })))

    try {
      await PageService.updatePages(pagesData)

      setFilteredPages(prevPages => prevPages.map(page => {
        if (checkedPages.includes(page.id)) {
          return { ...page, included: isIncluding }
        }
        return page
      }))

      setCheckedPages([])
      setIsHeaderCheckboxChecked(false)
      setPagesToggler(!pagesToggler)
    } catch (error) {
      console.error('Error updating including status:', error)
    }
  }

  const handleScanPages = async (all = false, included = false) => {
    const pageIds = []

    if (all) {
      pagesList.forEach(page => page.page_ids.forEach(id => pageIds.push(id)))
    } else if (included) {
      pagesList
        .filter(page => page.include_into_scanning)
        .forEach(page => page.page_ids.forEach(id => pageIds.push(id)))
    } else {
      pagesList
        .filter(page => checkedPages.includes(page.id))
        .forEach(page => page.page_ids.forEach(id => pageIds.push(id)))
    }

    setCheckedPages([])
    showNotification('UI Scan has started', 'info', 'Learn more', navigate)
    try {
      await ScanService.scanPages({ pageIds })
    } catch (error) {
      console.log(error)
      if (error?.response?.data?.error === "Please check your auth data.") {
        showNotification("Failed to scan. Please check your auth data", "error", 'Learn more', navigate, `/project/${projectID}`, { state: { activeTab: '2' }})
      }
    }
  }

  const handlePageChange = (event, value) => {
    setPage(value)
  }

  const handleCheckboxChange = (id) => {
    setCheckedPages(prevCheckedPages => {
      if (prevCheckedPages.includes(id)) {
        return prevCheckedPages.filter(pageId => pageId !== id)
      } else {
        return [...prevCheckedPages, id]
      }
    })
  }

  const handleClickCheckAllDropdown = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseCheckAllDropdown = () => {
    setAnchorEl(null)
  }

  const handleCheckAllPages = () => {
    const currentPageIds = paginatedPages.map(page => page.id)
    setCheckedPages(prevCheckedPages => {
      return [...new Set([...prevCheckedPages, ...currentPageIds])]
    })
    setIsHeaderCheckboxChecked(true)
    handleCloseCheckAllDropdown()
  }

  const handleCheckAllHierarchicalPages = () => {
    const allPageIds = sitemap.flatMap(page => getAllChildIds(page))
    setCheckedPages(allPageIds)
    setIsHeaderCheckboxChecked(true)
    handleCloseCheckAllDropdown()
  }

  const handleUncheckAllPages = () => {
    const currentPageIds = paginatedPages.map(page => page.id)
    setCheckedPages(prevCheckedPages => {
      return prevCheckedPages.filter(id => !currentPageIds.includes(id))
    })
    setIsHeaderCheckboxChecked(false)
    handleCloseCheckAllDropdown()
  }

  const handleUncheckAllHierarchicalPages = () => {
    setCheckedPages([])
    setIsHeaderCheckboxChecked(false)
    handleCloseCheckAllDropdown()
  }

  const handleSelectedIssueTypesChange = (value) => {
    if (value.length === 0) {
      setSelectedIssueTypes(allIssueTypes)
    } else {
      const checkedValue = value.map(type => type.description)
      setSelectedIssueTypes(checkedValue)
    }
  }

  const handleOpenProjectSettings = () => {
    navigate(`/project/${projectID}`)
  }

  const handleOpenProjectLoginSettings = () => {
    navigate(`/project/${projectID}`, { state: { activeTab: '2' }})
  }

  const handleOpenDeleteDialog = (pageId) => {
    if (pageId) {
      setPagesToDelete([pageId])
    } else {
      setPagesToDelete(checkedPages)
    }
    setDeleteOpen(true)
  }

  return (
    <Box sx={{ maxHeight: 'calc(100vh - 56px)', overflow: 'none' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <StatsSummary title="Total Pages" value={projectStat?.total_pages} color="primary" />
          <StatsSummary title="Pages Scanned" value={projectStat?.pages_scanned} color="primary" />
          <StatsSummary title="Pages with Issues" value={projectStat?.pages_with_issues} color="primary" />
          <StatsSummary
            title="Scanning Progress"
            value={ projectStat?.scanning_progress ? `${projectStat?.scanning_progress} %` : null }
            color={
              Number(projectStat?.scanning_progress) <= 50 ? '#d32f2f' :
                Number(projectStat?.scanning_progress) <= 80 ? '#ebbf05' :
                  '#198754'
            }
            tooltipText="Percentage of pages scanned for UI issues."
          />
          <StatsSummary
            title="Issue Rate"
            value={ projectStat?.issue_rate ? `${projectStat?.issue_rate} %` : null }
            color={
              Number(projectStat?.issue_rate) <= 20 ? '#198754' :
                Number(projectStat?.issue_rate) <= 80 ? '#ebbf05' :
                  '#d32f2f'
            }
            tooltipText={<>Percent of <b style={{ color: '#fff' }}>scanned</b> pages with issues.</>}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel>View</InputLabel>
            <Select
              size='small'
              value={view}
              onChange={(e) => setView(e.target.value)}
              label="View"
              sx={{ width: '140px' }}
            >
              <MenuItem value="table">Table</MenuItem>
              <MenuItem value="hierarchical">Hierarchical</MenuItem>
            </Select>
          </FormControl>
          <Tooltip
            title="To change resolution and other scanning parameters, go to General Settings."
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  backgroundColor: 'primary.main'
                },
              },
            }}
          >
            <IconButton sx={{ height: '40px' }} onClick={handleOpenProjectSettings}>
              <SettingsOutlinedIcon color='primary' />
            </IconButton>
          </Tooltip>
          { loginRequired ?
            <Tooltip
              title="To change login settings, go to Login Settings."
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: 'primary.main'
                  },
                },
              }}
            >
              <IconButton sx={{ height: '40px' }} onClick={handleOpenProjectLoginSettings}>
                <RoomPreferencesIcon color='primary' />
              </IconButton>
            </Tooltip> :
            null }
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        { view === 'table' ?
          <SearchBar pagesList={pagesList} handleSearch={handleSearch} />
          : <Box></Box>
        }
        <ActionButtons
          checkedPages={checkedPages}
          handleDiscoverMore={handleDiscoverMore}
          handleUpadateIncludingStatus={handleUpadateIncludingStatus}
          handleScanPages={handleScanPages}
          handleAddPageOpen={handleAddPageOpen}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
        />
      </Box>
      { view === 'table' ?
        <Box sx={{ display: 'flex' }}>
          <FormControl sx={{ mr: 1 }}>
            <InputLabel>Scan Status</InputLabel>
            <Select
              size='small'
              value={selectedScanStatus}
              onChange={(e) => setSelectedScanStatus(e.target.value)}
              label="Scan Status"
              sx={{ width: '140px', maxWidth: '140px' }}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Scanned">Scanned</MenuItem>
              <MenuItem value="Unscanned">Unscanned</MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            className='discoveryMainContainerUITestsFilter'
            size='small'
            multiple
            options={allIssueTypes}
            getOptionLabel={(option) => option.description}
            onChange={(event, value) => handleSelectedIssueTypesChange(value)}
            renderInput={(params) => (
              <TextField {...params} label="Issue type"/>
            )}
            sx={{ width: 'fit-content', minWidth: '174px' }}
          />
        </Box>
        : null
      }
      <Box>
        { view === 'table' ?
          <>
            <TableContainer className='pagesTableContainer' component={Paper}>
              <Table className='pagesTable' stickyHeader>
                <TableHeader
                  sortConfig={sortConfig}
                  handleSortChange={handleSortChange}
                  isHeaderCheckboxChecked={isHeaderCheckboxChecked}
                  includedIntoScanningTotal={includedIntoScanningTotal}
                  paginatedPages={paginatedPages}
                  setCheckedPages={setCheckedPages}
                  setIsHeaderCheckboxChecked={setIsHeaderCheckboxChecked}
                  anchorEl={anchorEl}
                  open={open}
                  handleClickCheckAllDropdown={handleClickCheckAllDropdown}
                  handleCloseCheckAllDropdown={handleCloseCheckAllDropdown}
                  handleCheckAllPages={handleCheckAllPages}
                  handleUncheckAllPages={handleUncheckAllPages}
                />
                <TableContent
                  pagesList={pagesList}
                  prevPagesList={prevPagesList}
                  paginatedPages={paginatedPages}
                  checkedPages={checkedPages}
                  selectedScanStatus={selectedScanStatus}
                  handleCheckboxChange={handleCheckboxChange}
                  handleOpenDeleteDialog={handleOpenDeleteDialog}
                />
              </Table>
            </TableContainer>
            { paginatedPages.length ?
              <PaginationComponent
                page={page}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                count={totalPages}
                handlePageChange={handlePageChange}
              /> : null
            }
          </> :
          <TableContainer className='pagesTableContainer' component={Paper} sx={{ boxShadow: 'none', maxHeight: 'calc(100vh - 238px)', overflowY: 'scroll' }}>
            <Table className='pagesTable' stickyHeader>
              <HierarchicalTableHeader
                isHeaderCheckboxChecked={isHeaderCheckboxChecked}
                includedIntoScanningTotal={includedIntoScanningTotal}
                anchorEl={anchorEl}
                open={open}
                handleCheckAll={handleCheckAll}
                handleClickCheckAllDropdown={handleClickCheckAllDropdown}
                handleCloseCheckAllDropdown={handleCloseCheckAllDropdown}
                handleCheckAllHierarchicalPages={handleCheckAllHierarchicalPages}
                handleUncheckAllHierarchicalPages={handleUncheckAllHierarchicalPages}
              />
              <HierarchicalTableContent
                sitemap={sitemap}
                prevSitemap={prevSitemap}
                checkedPages={checkedPages}
                setCheckedPages={setCheckedPages}
                handleOpenDeleteDialog={handleOpenDeleteDialog}
              />
            </Table>
          </TableContainer> }
      </Box>
      <DeletePagesConfirmationModal
        deleteOpen={deleteOpen}
        setDeleteOpen={setDeleteOpen}
        pagesToggler={pagesToggler}
        setPagesToggler={setPagesToggler}
        pagesToDelete={pagesToDelete}
        setPagesToDelete={setPagesToDelete}
        setCheckedPages={setCheckedPages}
      />
    </Box>
  )
}

export default PagesContent