import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const useTrackPreviousPage = () => {
  const location = useLocation()

  useEffect(() => {
    sessionStorage.setItem("previousPath", location.pathname)
  }, [location.pathname])
}

export default useTrackPreviousPage
