import React, { useState } from 'react'
import { List, ListItemButton, ListItemText, Collapse, IconButton } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

const Steps = ({ steps, handleSeek, isDraft }) => {
  const initializeOpenState = (steps) => {
    const openState = {}
    const setAllOpen = (items) => {
      items.forEach((item) => {
        openState[item.id] = true
        if (item?.children?.length > 0) {
          setAllOpen(item.children)
        }
      })
    }
    setAllOpen(steps)
    return openState
  }

  const [open, setOpen] = useState(initializeOpenState(steps))
  const [activeStep, setActiveStep] = useState(steps[0]?.id)

  const handleToggle = (item) => {
    setOpen((prevState) => ({
      ...prevState,
      [item.id]: !prevState[item.id],
    }))
  }

  const handleItemClick = (item) => {
    if (item.breakpoint !== undefined) {
      handleSeek(item.breakpoint)
    }
    setActiveStep(item.id)
  }

  const renderListItems = (items) => {
    return items.map((item, index) => (
      <React.Fragment key={item.id}>
        <ListItemButton
          className="testCaseDetailsTabContentStepsItem"
          onClick={() => handleItemClick(item)}
          sx={{ backgroundColor: !isDraft && activeStep === item.id ? 'rgba(69, 116, 210, 0.1)' : 'transparent' }}
        >
          {item?.children?.length > 0 && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                handleToggle(item)
              }}
              sx={{ ml: -0.5 }}
            >
              {open[item.id] ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
          <ListItemText
            primary={item.label ? <><strong>{index + 1}.</strong> {item.label}</> : <><strong>{index + 1}.</strong> empty label</>}
            sx={{ ml: 0.5, wordBreak: 'break-word' }}
          />
        </ListItemButton>
        {item?.children?.length > 0 && (
          <Collapse in={open[item.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{ pl: 2 }}>
              {renderListItems(item.children)}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    ))
  }

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', pb: 0 }}
      component="nav"
    >
      {renderListItems(steps)}
    </List>
  )
}

export default Steps
